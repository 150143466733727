const Footer = () => {
  return (
    <>
      <div>
        <div className="section-body">
          <footer className="admin-footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  Copyright © 2023 <a href="#">Arya Publishers</a>.
                </div>
                <div className="col-md-6 col-sm-12 text-md-right">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <a href="fake_url">
                        Designed by{" "}
                        <a href="https://webanixsolutions.com/">
                          Webanix Pvt Ltd
                        </a>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Footer;
