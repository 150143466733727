import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
// import { MultiSelect } from "react-multi-select-component";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../Toaster";
import axiosPrivate from "../../utils/http/axiosPrivate";
import classes from "../userForm/addUserStyle.module.css";
import moment from "moment/moment";
import { Calendar } from 'primereact/calendar';

//mobile validation check ragex
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// validation

const validationRules = {
  isbn: Yup.string()
    .max(13, "ISBN must not exceed 13 characters")
    .required("ISBN is required"),

  title: Yup.string()
    .max(120, "Title must not exceed 120 characters")
    .required("Title is required"),

  alias: Yup.string()
    .max(13, "Alias must not exceed 13 characters")
    .required("Alias is required"),

};

const AddBook = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [bookCategories, setBookCategories] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [bookSubCategories, setBookSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    axiosPrivate
      .get("publishers")
      .then((book_categories) => {
        setBookCategories(book_categories?.data?.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              formik.setFieldError(
                error.response.data.errors[i].param,
                error.response.data.errors[i].msg
              );
            }
          }
        }
      });
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("publishers")
      .then((publishers) => {
        setPublishers(publishers?.data?.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              formik.setFieldError(
                error.response.data.errors[i].param,
                error.response.data.errors[i].msg
              );
            }
          }
        }
      });
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("currency")
      .then((currency) => {
        setCurrency(currency?.data?.data);
        console.log(currency?.data?.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              formik.setFieldError(
                error.response.data.errors[i].param,
                error.response.data.errors[i].msg
              );
            }
          }
        }
      });
  }, []);

  // useEffect(() => {
  //   // Fetch book sub-categories based on selectedCategory and set in state
  //   if (selectedCategory) {
  //     axiosPrivate
  //       .get(`book-categories/sub-categories/${selectedCategory}`)
  //       .then((book_sub_cat) => {
  //         setBookSubCategories(book_sub_cat?.data?.data);
  //       })
  //       .catch((error) => {
  //         // Handle error
  //       });
  //   }
  // }, [selectedCategory]);

  useEffect(() => {
    if (id) {
      axiosPrivate
        .get(`books/${id}`)
        .then((response) => {
          showSuccessToast("Book details fetched successfully");
          let formattedYear;
          if (response?.data?.data?.year) {
            const yearString = response.data.data.year;
            formattedYear = moment(`${yearString}-01-01`, "DD-MM-YYYY").year();
          }
          const formattedPubDate = moment(response?.data?.data.pub_date).format("DD-MM-YYYY");
          setSelectedCategory(response?.data?.data?.book_category);
          formik.setValues({
            ...response?.data?.data,
            pub_date: formattedPubDate,
            year: formattedYear
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              for (let i = 0; i < error?.response?.data?.errors?.length; i++) {
                formik.setFieldError(
                  error?.response?.data?.errors[i].param,
                  error?.response?.data.errors[i].msg
                );
              }
            }
          }
        });
    }
  }, [id]);

  const formik = useFormik({
    initialValues: {
      title: "",
      isbn: "",
      alias: "",
      currency: "",
    },
    validationSchema: Yup.object(validationRules),

    onSubmit: (values, { setSubmitting, setFieldError }) => {
      if (id) {
        console.log(values);
        axiosPrivate
          .put(`books/${id}`, values)
          .then((response) => {
            console.log("updated",values);
            showSuccessToast("Book Updated Successfully");
            navigate("/admin/books");
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 422) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  setFieldError(
                    error.response.data.errors[i].param,
                    error.response.data.errors[i].msg
                  );
                }
              }
            }
          });
      } else {
        try {
          axiosPrivate
            .post('books', values)
            .then((response) => {
              showSuccessToast("Book created successfully");
              navigate('/admin/books');
            })
            .catch((error) => {
              if (typeof error.response.data.errors == 'array') {
                showErrorToast(error.response.data.errors[0]?.msg);
              } else {
                showErrorToast(error.response.data.errors?.msg ?? error.response.errors?.message);
              }
            });
        } catch (error) {
          console.error(error);
        }
      }
    }
  });


  return (
    <>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <form onSubmit={(e) => formik.handleSubmit(e)}>
              <div
                className="tab-pane fade show active"
                id="book-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                      Book Details :
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                    
                         {/* ISBN */}
                         <div className="col-md-4 col-sm-12">
                        <label>
                          ISBN:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="isbn"
                            name="isbn"
                            type="text"
                            className="form-control"
                            placeholder="Enter ISBN *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.isbn}
                            maxLength={13}
                          />
                          {formik.touched.isbn && formik.errors.isbn ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.isbn}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* name */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                          Title:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Title *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                          />
                          {formik.touched.title && formik.errors.title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.title}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Alias */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Alias:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="alias"
                            name="alias"
                            type="text"
                            className="form-control"
                            placeholder="Enter alias *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.alias}
                            maxLength={13}
                          />
                           {formik.touched.alias && formik.errors.alias ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.alias}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Book Category Id */}
                      {/* <div className="col-md-4 col-sm-12">
                        <label>
                          Book Category :<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <select
                            id="publisher"
                            name="publisher"
                            className="form-control"
                            onChange={(e) => {
                              formik.handleChange(e);
                              setSelectedCategory(e.target.value);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.publisher}
                          >
                            <option value="" label="Select a book category" />
                            {bookCategories.map((book) => (
                              <option
                                key={book.id}
                                value={book.id}
                                label={book.name}
                              />
                            ))}
                          </select>
                        </div>
                      </div> */}

                      {/* Book Sub Category Id */}
                      {/* <div className="col-md-4 col-sm-12">
                        <label>
                          Book Sub Category :<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <select
                            id="type"
                            name="type"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.type}
                          >
                            <option value="" label="Select a book sub category" />
                            {bookSubCategories.map((subCategory) => (
                              <option
                                key={subCategory.id}
                                value={subCategory.id}
                                label={subCategory.name}
                              />
                            ))}
                          </select>
                        </div>
                      </div> */}

                      {/* Prising type */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Prising Type :
                        </label>

                        <div className="form-group">
                          <input
                            id="prising_type"
                            name="prising_type"
                            type="text"
                            className="form-control"
                            placeholder="Enter pricing type"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.prising_type}
                          />
                        </div>
                      </div>

                      {/* Author */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Author :
                        </label>

                        <div className="form-group">
                          <input
                            id="author"
                            name="author"
                            type="text"
                            className="form-control"
                            placeholder="Enter Book Author"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.author}
                          />
                        </div>
                      </div>

                      {/* Edition */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Edition :
                        </label>

                        <div className="form-group">
                          <input
                            id="edition"
                            name="edition"
                            type="text"
                            className="form-control"
                            placeholder="Enter Book Edition"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.edition}
                          />
                        </div>
                      </div>

                      {/* Volume */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Volume :
                        </label>

                        <div className="form-group">
                          <input
                            id="volume"
                            name="volume"
                            type="text"
                            className="form-control"
                            placeholder="Enter book volume"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.volume}
                          />
                        </div>
                      </div>

                      {/* Publication Date */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Publication Date :
                        </label>

                        <div className="form-group">
                          <input
                            id="pub_date"
                            name="pub_date"
                            type="date"
                            className="form-control"
                            placeholder="Enter book publication date"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pub_date}
                          />
                        </div>
                      </div>

                      {/* Year */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Year :
                        </label>
                        <Calendar
                          id="year"
                          name="year"
                          placeholder="Select a year"
                          value={formik.values.year ? new Date(formik.values.year, 0, 1) : null}
                          onChange={(e) => {
                            if (e.value instanceof Date) {
                              const selectedYear = e.value.getFullYear();
                              formik.setFieldValue("year", selectedYear);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          view="year"
                          dateFormat="yy"
                        />

                        {/* <div className="form-group">
                          <input
                            id="year"
                            name="year"
                            type="text"
                            className="form-control"
                            placeholder="Enter year *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.year}
                          />
                        </div> */}
                      </div>

                      {/* MRP Rate */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          MRP Rate :
                        </label>

                        <div className="form-group">
                          <input
                            id="mrp_rate"
                            name="mrp_rate"
                            type="number"
                            className="form-control"
                            placeholder="Enter MRP Rate"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mrp_rate}
                          />
                        </div>
                      </div>

                      {/* MRP Rate1 */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          MRP Rate1 :
                        </label>

                        <div className="form-group">
                          <input
                            id="mrp_rate1"
                            name="mrp_rate1"
                            type="number"
                            className="form-control"
                            placeholder="Enter MRP Rate 1"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mrp_rate1}
                          />
                        </div>
                      </div>

                      {/* Discount */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Discount :
                        </label>

                        <div className="form-group">
                          <input
                            id="discount"
                            name="discount"
                            type="number"
                            className="form-control"
                            placeholder="Enter discount"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.discount}
                          />
                        </div>
                      </div>

                      {/* Sales Discount */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Sales Discount :
                        </label>

                        <div className="form-group">
                          <input
                            id="sales_discount"
                            name="sales_discount"
                            type="number"
                            className="form-control"
                            placeholder="Enter sales discount"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.sales_discount}
                          />
                        </div>
                      </div>

                      {/* Bank Rate */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Bank Rate :
                        </label>

                        <div className="form-group">
                          <input
                            id="bank_rate"
                            name="bank_rate"
                            type="number"
                            className="form-control"
                            placeholder="Enter Bank rate"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bank_rate}
                          />
                        </div>
                      </div>

                      {/* Currency */}
                      <div className="col-md-4 col-sm-12">
                        <label>Currency:</label>
                        <div className="form-group">
                          <select
                            id="currency"
                            name="currency"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.currency}
                          >
                            <option value="" label="Select a currency" />
                            {currency.map((cur) => (
                              <option
                                key={cur.id}
                                value={cur.symbol}
                                label={cur.name}
                              />
                            ))}
                          </select>
                        </div>
                      </div>


                      {/* Binding */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Binding :
                        </label>

                        <div className="form-group">
                          <input
                            id="binding"
                            name="binding"
                            type="text"
                            className="form-control"
                            placeholder="Enter binding"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.binding}
                          />
                        </div>
                      </div>

                      {/* Publisher */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Publisher :
                        </label>

                        <div className="form-group">
                          <select
                            id="publisher"
                            name="publisher"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.publisher}
                          >
                            <option value="" label="Select a publisher" />
                            {publishers.map((publisher) => (
                              <option
                                key={publisher.id}
                                value={publisher.id}
                                label={publisher.name}
                              />
                            ))}
                          </select>
                        </div>
                      </div>

                      {/* Subject */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Subject :
                        </label>

                        <div className="form-group">
                          <input
                            id="subject"
                            name="subject"
                            type="text"
                            className="form-control"
                            placeholder="Enter Subject"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.subject}
                          />
                        </div>
                      </div>

                      {/* Subject2 */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Subject 2 :
                        </label>

                        <div className="form-group">
                          <input
                            id="subject2"
                            name="subject2"
                            type="text"
                            className="form-control"
                            placeholder="Enter Subject 2"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.subject2}
                          />
                        </div>
                      </div>

                      {/* Sub Subject */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Sub Subject :
                        </label>

                        <div className="form-group">
                          <input
                            id="sub_subject"
                            name="sub_subject"
                            type="text"
                            className="form-control"
                            placeholder="Enter Sub Subject 1"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.sub_subject}
                          />
                        </div>
                      </div>


                      {/* Sub Subject2 */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Sub Subject 2 :
                        </label>

                        <div className="form-group">
                          <input
                            id="sub_subject2"
                            name="sub_subject2"
                            type="text"
                            className="form-control"
                            placeholder="Enter Sub Subject 2"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.sub_subject2}
                          />
                        </div>
                      </div>

                      {/* Units */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Units :
                        </label>

                        <div className="form-group">
                          <input
                            id="units"
                            name="units"
                            type="number"
                            className="form-control"
                            placeholder="Enter Units"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.units}
                          />
                        </div>
                      </div>

                      {/* Rate of Duty */}
                      <div className="col-md-4 col-sm-12">
                        <label>
                          Rate of Duty :
                        </label>

                        <div className="form-group">
                          <input
                            id="rate_of_duty"
                            name="rate_of_duty"
                            type="text"
                            className="form-control"
                            placeholder="Enter Rate of duty"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.rate_of_duty}
                          />
                        </div>
                      </div>

                      <div className="col-md-12 col-sm-12 submit-btn">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => navigate("/admin/books")}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBook;
