import React, { useEffect, useMemo, useState } from "react";
import { Pagination, Search, TableHeader } from "../DataTable";
import Header from "../Header";

const DataTable2 = ({ lists, headers, searchItems }) => {
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const filterListLengths = [10, 25, 50, 100];

  useEffect(() => {
    setComments(lists);
    setIsLoading(false); // Assuming data loading is complete here
  }, [lists]);

  const listData = useMemo(() => {
    let computedData = comments;

    if (search && searchItems?.length > 0) {
      computedData = computedData?.filter((filterData) =>
        searchItems?.some((field) =>
          filterData[field]?.toLowerCase()?.includes(search?.toLowerCase())
        )
      );
    }

    setTotalItems(computedData?.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }
    // console.log("Computed data is :",computedData);
    return computedData.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  const changeItemsPerPage = (e) => {
    setItemsPerPage(e.target.value);
  };

  return (
    <div className="row w-100">
      <div className="col mb-3 text-center">
        <div className="row mb-4">
          <div className="col-md-6 col-sm-6">
            <Search
              onSearch={(value) => {
                setSearch(value);
                setCurrentPage(1);
              }}
            />
          </div>

          <div className="col-sm-12 col-md-6 d-flex flex-row-reverse ">
            <div className="float-left ml-3">
              <label className="mr-2">Show</label>
              <select
                name="list_length"
                className="form-select"
                onChange={changeItemsPerPage}
              >
                {filterListLengths?.map((value, key) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </select>
              <span className="ml-2">entries</span>
            </div>
          </div>
        </div>

        <table className="table table-hover table-fixed">
          <TableHeader
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {listData?.map((comment, idx) => (
              <tr key={idx}> {/* Assuming a unique id field */}
              <td style={{ textAlign: "left" }}>{idx+1}</td>
                {headers?.map((col, colIdx) => (
                  <td key={`${comment.id}-${colIdx}`} style={{ textAlign: "left" }}>
                    {col.field !== "image" ? (
                      comment[col.field]
                    ) : (
                      <img
                        src={comment[col.field]}
                        alt={`Image ${col.field}`}
                        style={{ width: "70px", height: "50px" }}
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        <div className="col-sm-12 col-md-12 d-flex justify-content-center align-items-center">
          <Pagination
            total={totalItems}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
};

export default DataTable2;
