import { Fragment, useContext, useState } from "react";
import { CgUserList } from "react-icons/cg";
import { FaBook, FaBookMedical } from "react-icons/fa";
import { SiGradleplaypublisher } from "react-icons/si";
import { MdCurrencyRupee } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { Link } from "react-router-dom";
import userPng from "../../assets/images/user.png";
import AuthContext from "../../context/auth/AuthContext";
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { RiCashLine, RiFileTextLine } from "react-icons/ri";

const SideBar = () => {

  const { authPermissions } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);

  const hideSidebar = (e) => {
    e.preventDefault();
    setVisible(false)
  }

  return (
    <Fragment>
      <div className="flex justify-content-center">
        <Sidebar visible={visible} onHide={() => setVisible(false)} >
          <div id="left-sidebar" className="sidebar ">
            <h5 className="brand-name">{process.env.REACT_APP_APP_NAME}</h5>
            <div id="header_top" className="header_top false">
              <div className="container">
                <div className="hleft">
                  <Link to="/">
                    <a aria-current="page" className="header-brand active">
                      <img src="" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            {/* <nav id="left-sidebar-nav" className="sidebar-nav">
              <div className=""> */}
            <ul className="metismenu">
              <li className="">
                <span className="g_heading"></span>
              </li>
              <li className="">
                {/* <span className="">
                  <a aria-current="page" className="list-a" href="/events">
                    <AiOutlineArrowDown className="mr-2" size={18} />
                    Pragati
                    <i className="caret-left"></i>
                  </a>
                </span> */}
                <ul className="collapse collapse collapse collapse collapse collapse collapse collapse collapse collapse in">
                  {/* <li className="">
                    <span className="">
                      <Link className="list-a" to="/admin/dashboard">
                        <RxHome className="mr-2" size="16" />
                        Dashboard
                      </Link>
                    </span>
                  </li> */}
                  {/* NM Staff Start */}
                  {/* {authPermissions.includes("User-List") === true ? ( */}
                  <li className="" onClick={(e) => { hideSidebar(e) }}>
                    <span className="">
                      <Link className="list-a" to="/admin/user">
                        <FiUsers className="mr-2" size="16" />
                        Users
                      </Link>
                    </span>
                  </li>
                  {/* ) : null} */}
                  {/* NM Staff End  */}
                  {/* role & permission */}
                  {/* {authPermissions.includes("User-List") === true ? ( */}
                  <li className="" onClick={(e) => { hideSidebar(e) }}>
                    <span className="">
                      <Link className="list-a" to="/admin/roles">
                        <CgUserList className="mr-2" size="16" />
                        Roles & Permissions
                      </Link>
                    </span>
                  </li>
                  {/* ) : null} */}
                  {/* Book Publishers Start */}
                  <li className="" onClick={(e) => { hideSidebar(e) }}>
                    <span className="">
                      <Link className="list-a" to="/admin/book-publishers">
                        <FaBookMedical className="mr-2" size="16" />
                        Book Publishers
                      </Link>
                    </span>
                  </li>
                  {/* Books Start */}
                  <li className="" onClick={(e) => { hideSidebar(e) }}>
                    <span className="">
                      <Link className="list-a" to="/admin/books">
                        <FaBook className="mr-2" size="16" />
                        Books
                      </Link>
                    </span>
                  </li>
                  {/* Books End */}
                  {/* Publishers Start */}
                  {/* {authPermissions.includes("User-List") === true ? ( */}
                  {/* <li className="" onClick={(e) => { hideSidebar(e) }}>
                    <span className="">
                      <Link className="list-a" to="/admin/publishers">
                        <SiGradleplaypublisher className="mr-2" size="16" />
                        Publishers
                      </Link>
                    </span>
                  </li> */}
                  {/* ) : null} */}
                  {/* Publishers End */}
                  {/* Currency Start */}
                  {/* {authPermissions.includes("User-List") === true ? ( */}
                  <li className="" onClick={(e) => { hideSidebar(e) }}>
                    <span className="">
                      <Link className="list-a" to="/admin/currency">
                        <MdCurrencyRupee className="mr-2" size="16" />
                        Currency
                      </Link>
                    </span>
                  </li>
                  {/* ) : null} */}
                  {/* Cash Memo Start */}
                  {/* {authPermissions.includes("User-List") === true ? ( */}
                  <li className="" onClick={(e) => { hideSidebar(e) }}>
                    <span className="">
                      <Link className="list-a" to="/admin/cash-memo">
                        <RiCashLine className="mr-2" size="16" />
                        Cash Memo
                      </Link>
                    </span>
                  </li>
                  <li className="" onClick={(e) => { hideSidebar(e) }}>
                    <span className="">
                      <Link className="list-a" to="/admin/day-report">
                        <RiFileTextLine className="mr-2" size="16" />
                        Day Report
                      </Link>
                    </span>
                  </li>
                  {/* <li className="" onClick={(e) => { hideSidebar(e) }}>
                    <span className="">
                      <Link className="list-a" to="/admin/sold-book-report">
                        <RiFileTextLine className="mr-2" size="16" />
                        Sold Book Report
                      </Link>
                    </span>
                  </li> */}
                  {/* ) : null} */}
                  {/* Cash Memo End */}
                  {/* Holidays Start */}
                  {/* {authPermissions.includes("User-List") === true ? ( */}
                  {/* <li className="">
                    <span className="">
                      <Link className="list-a" to="/admin/holiday">
                        <GiFireFlower className="mr-2" size="16" />
                        Holiday
                      </Link>
                    </span>
                  </li> */}
                  {/* ) : null} */}
                  {/* Holidays End */}
                  {/* Attendance Start */}
                  {/* {authPermissions.includes("User-List") === true ? ( */}
                  {/* <li className="">
                    <span className="">
                      <Link className="list-a" to="/admin/attendance">
                        <GiFireFlower className="mr-2" size="16" />
                        Attendance
                      </Link>
                    </span>
                  </li> */}

                  {/* <li className="">
                    <span className="">
                      <Link className="list-a" to="/admin/leave">
                        <GiFireFlower className="mr-2" size="16" />
                        Leave
                      </Link>
                    </span>
                  </li> */}


                  {/* ) : null} */}
                  {/* Attendance End */}
                  {/* Payroll Start */}
                  {/* {authPermissions.includes("User-List") === true ? ( */}
                  {/* <li className="">
                    <span className="">
                      <Link className="list-a" to="/admin/payroll">
                        <GiFireFlower className="mr-2" size="16" />
                        Payroll
                      </Link>
                    </span>
                  </li> */}
                  {/* ) : null} */}
                  {/* Payroll End */}


                  {/* <li className="">
                    <span className="">
                      <Link className="list-a" to="/admin/advance">
                        <GiFireFlower className="mr-2" size="16" />
                        Advance
                      </Link>
                    </span>
                  </li> */}
                </ul>
              </li>
            </ul>
            {/* </div>
            </nav> */}

          </div>
        </Sidebar>
        <Button icon="pi pi-bars" size="large" onClick={() => setVisible(true)} />
      </div>
    </Fragment>
  );
};

export default SideBar;
