
import './App.css';
import './assets/css/main.css'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./components/layouts/Main";
import SingIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp.js";
import AuthState from "./context/auth/AuthState";
import AlertState from "../src/context/Alert/AlertState";
import AuthMain from "./components/layouts/AuthMain";
import UserList from "./pages/userForm/UserList";
import AddUser from "./pages/userForm/adduser";
import AddRoles from "./pages/Roles/AddRoles";
import Roles from "./pages/Roles/Roles";
import RolePermission from "./pages/Roles/RolePermission";
import BookCategoriesList from "./pages/BookCategory/BookCategoriesList";
import AddBookCategory from "./pages/BookCategory/AddBookCategory";
import BookSubCategory from "./pages/BookSubCategory/BookSubCategoriesList";
import BookList from "./pages/Books/BookList";
import AddBook from "./pages/Books/addBook";
import AddPublisher from "./pages/Publishers/AddPublisher";
import PublishersList from "./pages/Publishers/PublisherList";
import AddCurrency from "./pages/Currency/AddCurrency";
import CurrencyList from "./pages/Currency/CurrencyList";
import CashMemoList from "./pages/CashMemo/CashMemoList";
import AddCashMemo from "./pages/CashMemo/AddCashMemo";
import LoaderState from "./context/Loader/LoaderState";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditRoles from './pages/Roles/EditRoles.js';
import EditCashMemo from './pages/CashMemo/EditCashMemo.js';
import DayReport from './pages/Reports/DayReport.js';
import SoldBookReport from './pages/Reports/SoldBookReport.js';
import DayReportView from './pages/Reports/DayReportView.js';
import ViewCashMemo from './pages/CashMemo/ViewCashMemo.js';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AuthState>
        <AlertState>
          <LoaderState>
            <Routes>
              <Route path="/" element={<AuthMain />}>
                <Route path="auth/signin" element={<SingIn />} />
                <Route path="auth/signup" element={<SignUp />} />
              </Route>

              <Route path="/admin" element={<Main />}>

                <Route path="user" element={<UserList />} />
                <Route path="user/add" element={<AddUser />} />
                <Route path="user/edit/:id" element={<AddUser />} />
                <Route path="profile/:id" element={<AddUser />} />

                <Route path="roles" element={<Roles />} />
                <Route path="roles/add" element={<AddRoles />} />
                <Route path="roles/edit/:id" element={<EditRoles />} />
                <Route path="roles/permissions/:roleId" element={<RolePermission />} />

                <Route path="book-publishers" element={<BookCategoriesList />} />
                <Route path="book-publishers/add" element={<AddBookCategory />} />
                <Route path="book-publishers/edit/:id" element={<AddBookCategory />} />

                <Route path="book-sub-categories/:id" element={<BookSubCategory />} />

                <Route path="books" element={<BookList />} />
                <Route path="books/add" element={<AddBook />} />
                <Route path="books/edit/:id" element={<AddBook />} />

                <Route path="publishers" element={<PublishersList />} />
                <Route path="publishers/add" element={<AddPublisher />} />
                <Route path="publishers/edit/:id" element={<AddPublisher />} />

                <Route path="currency" element={<CurrencyList />} />
                <Route path="currency/add" element={<AddCurrency />} />
                <Route path="currency/edit/:id" element={<AddCurrency />} />

                <Route path="cash-memo" element={<CashMemoList />} />
                <Route path="cash-memo/add" element={<AddCashMemo />} />
                <Route path="cash-memo/edit/:id" element={<EditCashMemo />} />
                <Route path="cash-memo/view/:id" element={<ViewCashMemo />} />
                <Route path="day-report" element={<DayReport />} />
                <Route path="day-report/view/:isbn" element={<DayReportView />} />
                <Route path="sold-book-report" element={<SoldBookReport />} />
              </Route>
            </Routes>
          </LoaderState>
        </AlertState>
      </AuthState>
    </BrowserRouter>
  );
}

export default App;
