import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosPrivate from "../../utils/http/axiosPrivate";
import { toast } from "react-toastify";
import { showErrorToast, showSuccessToast } from "../../Toaster";
import DataTable2 from "../../context/components/DataTable2";
import moment from "moment";

const SoldBookReport = () => {
  const [userState, setUserState] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isbn, setIsbn] = useState("");

  useEffect(() => {
    if (isbn) {
      // Check if ISBN is provided before making the API call
      fetchData();
    }
  }, [isbn]);

//   useEffect(() => {
//     const filteredData = data.filter((item) => {
//       const itemDate = moment(item.created_at).format("DD/MM/YYYY");
//       console.log(itemDate);
//       if (fromDate === "" || toDate === "") return true;
//       else return itemDate >= fromDate && itemDate <= toDate;
//     });

//     setUserState(filteredData);
//   }, [fromDate, toDate]);

  const fetchData = () => {
    axiosPrivate
      .get(`/book-sold-reports/${isbn}`)
      .then((response) => {
        const data = response.data.data;
        setUserState(data);
        showSuccessToast("Data Fetched Successfully");
      })
      .catch((error) => {
        console.log(error);
        showErrorToast("Failed to fetch data");
      });
  };

  const headers = [
    { name: "Title", field: "title", sortable: true, classKey: "" },
    { name: "Doc", field: "doc", sortable: true, classKey: "" },
    { name: "ISBN", field: "isbn", sortable: true, classKey: "" },
    { name: "Author", field: "author", sortable: true, classKey: "" },
    { name: "Publisher", field: "publisher", sortable: true, classKey: "" },
    { name: "Quantity", field: "quantity", classKey: "" },
    { name: "Total Amount", field: "amount", classKey: "" },
  ];

  const searchItems = ["title", "author", "publisher", "isbn", "doc"];

  const filterData = userState.filter((item) => {
    const itemDate = moment(item.date).format(
      "DD-MM-YYYY"
    );
    console.log(item, item.created_at, toDate, fromDate);
    if (fromDate === "" || toDate === "") return true;
    else
      return (
        itemDate >= fromDate && itemDate <= toDate
      );
  });

  return (
    <>
      {/* Your JSX for header */}
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="user-list"
              role="tabpanel"
            >
            <div className="col-md-12 mb-3">
                                            <h3>Sold Book Report</h3>
                                        </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                  <div className="col-md-3">
                      <label>ISBN</label>
                      <input
                        className="form-control"
                        type="number"
                        name="isbn"
                        value={isbn}
                        onChange={(e) => setIsbn(e?.target?.value)}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label>From Date</label>
                      <input
                        className="form-control"
                        type="date"
                        name="from_date"
                        value={fromDate}
                        onChange={(e) => {
                          setFromDate(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <label>To Date</label>
                      <input
                        className="form-control"
                        type="date"
                        name="to_date"
                        value={toDate}
                        onChange={(e) => {
                          setToDate(e.target.value);
                        }}
                      />
                    </div>
                   
                    <div className="col-md-12 table-responsive">
                      {userState && (
                        <DataTable2
                          lists={filterData}
                          headers={headers}
                          searchItems={searchItems}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoldBookReport;
