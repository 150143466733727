import LoaderContext from "./LoaderContext";
import { useState } from "react";

const LoaderState = (props) => {

    const [loaderCheck, setLoaderCheck] = useState(false);

    return (
        <>
            <LoaderContext.Provider value={{loaderCheck, setLoaderCheck}}>
                {props.children}
            </LoaderContext.Provider>
        </>
    );
}

export default LoaderState;