import axios from "axios";
import { axiosPublic } from "./axiosPublic";

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

var userToken = JSON.parse(localStorage.getItem("userData") || "{}");

axiosApiInstance.interceptors.request.use(
  async (config) => {
    // GlobalLoader(true);
    config.headers = {
      Authorization: `Bearer ${userToken}`,
      Accept: "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    // GlobalLoader(false);
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status == 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      const newToken = JSON.parse(localStorage.getItem("userData") || "{}");
      userToken = newToken;
      axiosApiInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${newToken}`;
      // console.log(
      //   "token1",
      //   axiosApiInstance.defaults.headers.common["Authorization"]
      // );
      originalRequest.headers.Authorization = `Bearer ${newToken}`;
      // console.log("token2", originalRequest);
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

const refreshAccessToken = async () => {
  return new Promise(async (resolve, reject) => {
    const refreshToken = JSON.parse(
      localStorage.getItem("userRefreshToken") || "{}"
    );
    if (!(Object.keys(refreshToken).length == 0)) {
      await axiosPublic
        .post("refresh-token", { refresh_token: refreshToken })
        .then(
          (response) => {
            // console.log(response.data);
            localStorage.setItem(
              "userData",
              JSON.stringify(response.data.token)
            );
            resolve(response.data.token);
          },
          (error) => {
            reject(error.message);
          }
        );
    } else {
      // if (
      //   localStorage.getItem("userData") ||
      //   localStorage.getItem("userDetails")
      // ) {
        localStorage.removeItem("userData");
        localStorage.removeItem("userDetails");
      // }
      // const navigate = useNavigate();
      // navigate('/user-panel/signin');
      // window.location.href = '/user-panel/signin';
    }
  });
};

export default axiosApiInstance;
