import { useContext, useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, showSuccessToast } from "../../Toaster";
import axiosPrivate from "../../utils/http/axiosPrivate";
import AuthContext from "../../context/auth/AuthContext";
import AlertContext from "../../context/Alert/AlertContext";
import DataTable2 from "../../context/components/DataTable2";
import { FaGreaterThan } from "react-icons/fa";

const UserList = ({ authPermission }) => {
  const { authPermissions } = useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [userState, setUserState] = useState([]);

  const navigate = useNavigate();

  const headers = [
    { name: "NAME", field: "name", sortable: true, classKey: "" },
    { name: "EMAIL", field: "email", sortable: true, classKey: "" },
    { name: "CONTACT NUMBER", field: "mobile", sortable: true, classKey: "" },
    { name: "ROLE", field: "role_name", sortable: true, classKey: "" },
    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["name"];

  const getUsers = () => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("users", options)
      .then((users) => {
        // showSuccessToast("Users List Fetched Successfully");
        const usersData = users?.data?.data?.map((value, key) => {
          let buttons = [];
          buttons.push(
            <Link
              key="editButton##1"
              type="button"
              to={`/admin/user/edit/${value.id}`}
              className="btn btn-icon"
              title="Edit"
            >
              <BiEdit style={{ color: "green" }} size={18} />
            </Link>
          );
          buttons.push(
            <button
              key="deleteButton##1"
              type="button"
              data-id={value.id}
              onClick={() => userDeleteHandler(value.id)}
              className="btn btn-icon js-sweetalert"
              title="Delete"
            >
              <RiDeleteBinLine style={{ color: "red" }} size={17} />
            </button>
          );
          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
        });

        setUserState(usersData);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          showErrorToast(error?.response?.data?.message);
        }
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  // useEffect(() => {
  //   if (alertMessage !== null) {
  //     toast(alertMessage.message, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     setAlertMessage(null);
  //   }
  // }, [alertMessage]);

  const userDeleteHandler = (id) => {
    axiosPrivate
      .delete(`users/${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          navigate("/admin/user");
          getUsers();
          showSuccessToast("User Deleted Successfully!");
        }
      })
      .catch((error) => {
        if (error.response) {
          showErrorToast("Internal server error");
        }
      });
  };

  return (
    <>
      <div className="mx-sm-5 mx-0">
        <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center mb-3">
          <ul className="nav nav-tabs page-header-tab">
            <li className="nav-item">
              <div className="card-header">
                <div className="breadcrumb-area">
                  {/* header heading */}
                  <h1 className="card-title text-dark" style={{ fontWeight: "700", fontSize: "18px" }}>
                    Users
                  </h1>
                  {/* header routing */}
                  <ol className="headerroutes breadcrumb m-0 bg-none ">
                    <li className="item color-gray">|</li>
                    <li className="item">
                      <Link to="/admin" className="item mx-2" style={{ fontSize: "14px" }}>
                        Dashboard
                      </Link>
                    </li>
                    <li className="item">
                      <FaGreaterThan style={{ fontSize: "14px" }} />
                    </li>
                    <li className="item mx-2 mt-1" style={{ fontSize: "14px" }}>
                      Users list
                    </li>
                  </ol>
                </div>
              </div>
            </li>
          </ul>
          <div className="header-action">
            <Link to="/admin/user/add" className="text-decoration-none">
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="fe fe-plus mr-2" />
                Add
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div className="tab-pane fade show active" id="user-list" role="tabpanel">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    {userState !== null && (
                      <DataTable2 lists={userState} headers={headers} searchItems={searchItems} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



export default UserList;
