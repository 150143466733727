import { useContext, useEffect, useState } from "react";
import { Link, Navigate, Route, useNavigate } from "react-router-dom";
import axiosPrivate from "../../utils/http/axiosPrivate";
import { toast } from "react-toastify";
import { showErrorToast, showSuccessToast } from "../../Toaster";
import DataTable2 from "../../context/components/DataTable2";
import { AiOutlineEdit } from "react-icons/ai";

const DayReport = () => {
  const [userState, setUserState] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const navigate = useNavigate();
  const headers = [
    { name: "Title", field: "title", sortable: true, classKey: "" },
    { name: "ISBN", field: "isbn", sortable: true, classKey: "" },
    { name: "Author", field: "author", sortable: true, classKey: "" },
    { name: "Publisher", field: "publisher", sortable: true, classKey: "" },
    { name: "Quantity", field: "quantity", classKey: "" },
    { name: "Rate", field: "rate", classKey: "" },
    { name: "Actions", field: "actions", classKey: "" },
  ];
  const handleView = (isbn) => {
    navigate(`/admin/day-report/view/${isbn}`);
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);

  const fetchData = () => {
    axiosPrivate
      .get(`day-reports?from=${fromDate}&to=${toDate}`)
      .then((response) => {
        const data = response.data?.data;
        console.log(data);
        // Map through the data and add the "Actions" field
        const newData = data.map((item) => {
          return {
            ...item,
            actions: (
              <button
                className="btn btn-primary btn-sm"
                onClick={() => handleView(item.isbn)}
              >
                View
              </button>
            ),
          };
        });
        setUserState(newData);
        showSuccessToast("Data Fetched Successfully");
      })
      .catch((error) => {
        if(error.response?.data?.data?.length == 0) {
            setUserState([]);
            showErrorToast("No Data Found");
        }
        console.log(error);
        if (error.response) {
          showErrorToast(error?.response?.data?.errors?.msg);
        }
      });
  };

  const searchItems = ["title", "author", "book_category", "isbn"];

  return (
    <>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="user-list"
              role="tabpanel"
            >
              <div className="col-md-12 mb-3">
                <h1
                  className="card-title text-dark"
                  style={{ fontWeight: "700", fontSize: "18px" }}
                >
                  Day Report
                </h1>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <label>From Date</label>
                      <input
                        className="form-control"
                        type="date"
                        name="from_date"
                        onChange={(e) => {
                          setFromDate(e.target?.value);
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <label>To Date</label>
                      <input
                        className="form-control"
                        type="date"
                        name="to_date"
                        onChange={(e) => {
                          setToDate(e.target?.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="table-responsive">
                    {userState !== null && (
                      <DataTable2
                        lists={userState}
                        headers={headers}
                        searchItems={searchItems}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DayReport;
