import { FaUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/AuthContext";
import { useContext } from "react";
import SideBar from "./SideBar";

const Header = (props) => {

  const navigate = useNavigate();

  const { setUserDetails } = useContext(AuthContext);

  // Retrieve user details from localStorage
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || {};
  const userId = userDetails ? userDetails.userId : null; // Extract user ID

  const onLogOut = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('userRefreshToken');
    setUserDetails({});
    navigate('/auth/signin');
  };

  return (
    <div>
      <div id="page_top" className="section-body" style={{ backgroundColor: "#004583" }}>
        <div className="container-fluid">
          <div className="page-header">
            <div className="left">
              <div className="custom-sidebar">
                <SideBar />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <span style={{ textAlign: "center", color: "white", fontSize: '24px' }}>
                ARYAS PUBLISHERS DISTRIBUTORS PVT LTD
              </span>
            </div>
            <div className="right">
              <div className="notification d-flex">
                <div className="dropdown d-flex">
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                    <ul className="list-unstyled feeds_widget">
                      {/* Notification items */}
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-check"></i>
                        </div>
                        <div className="feeds-body">
                          <h4 className="title text-danger">
                            Issue Fixed{" "}
                            <small className="float-right text-muted">
                              11:05
                            </small>
                          </h4>
                          <small>
                            WE have fix all Design bug with Responsive
                          </small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-user"></i>
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            New User
                            <small className="float-right text-muted">
                              10:45
                            </small>
                          </h4>
                          <small>I feel great! Thanks team</small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-thumbs-o-up"></i>
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            7 New Feedback{" "}
                            <small className="float-right text-muted">
                              Today
                            </small>
                          </h4>
                          <small>
                            It will give a smart finishing to your site
                          </small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-question-circle"></i>
                        </div>
                        <div className="feeds-body">
                          <h4 className="title text-warning">
                            Server Warning{" "}
                            <small className="float-right text-muted">
                              10:50
                            </small>
                          </h4>
                          <small>Your connection is not private</small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-shopping-cart"></i>
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            7 New Orders{" "}
                            <small className="float-right text-muted">
                              11:35
                            </small>
                          </h4>
                          <small>You received a new order from Tina.</small>
                        </div>
                      </li>
                    </ul>
                    <div className="dropdown-divider"></div>
                    <a href="fake_url" className="dropdown-item text-center text-muted-dark readall">
                      Mark all as read
                    </a>
                  </div>
                </div>

                {/* User Profile */}
                <div className="dropdown d-flex">
                  <FaUserCircle
                    className="text-white dropdown-toggle nav-link d-md-flex btn btn-icon ml-1"
                    style={{ fontSize: "45px" }}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  />
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to={`/admin/profile/${userId}`}>
                        <i className="dropdown-icon fe fe-edit" style={{ fontSize: "15px" }}></i>
                        Profile
                      </Link>
                    </li>
                    <li>
                      <a className="dropdown-item" onClick={onLogOut}>
                        <i className="dropdown-icon fe fe-log-out"></i> Log out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
