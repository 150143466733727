import { useContext, useEffect, useState } from "react";
import { Link,Navigate,Route,useNavigate,
  useParams,
} from "react-router-dom";
import axiosPrivate from "../../utils/http/axiosPrivate";
import { toast } from "react-toastify";
import { showErrorToast, showSuccessToast } from "../../Toaster";
import DataTable2 from "../../context/components/DataTable2";
import { AiOutlineEdit } from "react-icons/ai";
const DayReport = () => {
  const [userState, setUserState] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const {isbn} = useParams();
  
  const navigate = useNavigate();
  const headers = [
    { name: "Doc", field: "doc", sortable: true, classKey: "" },
    { name: "Title", field: "title", sortable: true, classKey: "" },
    { name: "ISBN", field: "isbn", sortable: true, classKey: "" },
    { name: "Author", field: "author", sortable: true, classKey: "" },
    { name: "Publisher", field: "publisher", sortable: true, classKey: "" },
    { name: "Qty.", field: "quantity", classKey: "" },
    { name: "Curr.", field: "currency", classKey: "" },
    { name: "Total Amount", field: "amount", classKey: "" },
    { name: "Actions", field: "actions", classKey: "" },
  ];

  useEffect(() => {
      fetchData();
  }, [fromDate, toDate]);

  const handleView = (id) => {
    navigate(`/admin/cash-memo/view/${id}`);
};

  const fetchData = () => {
      axiosPrivate
          .get(`/day-reports/${isbn}?from=${fromDate}&to=${toDate}`)
          .then((response) => {
            const data = response.data?.data;
            console.log(data);
            // Map through the data and add the "Actions" field
            const newData = data.map(item => {
                return {
                    ...item,
                    actions: <button className="btn btn-primary btn-sm" onClick={() => handleView(item.cash_memo_id)}>View</button>
                };
            });
            setUserState(newData);
            showSuccessToast("Data Fetched Successfully");
        })
          .catch((error) => {
              if (error.response) {
                  showErrorToast(error?.response?.data?.errors?.msg);
              }
          });
  };

  const searchItems = ["title", "author", "book_category", "isbn"];

  return (
    <>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="user-list"
              role="tabpanel"
            >
              <div className="col-md-12 mb-3">
                <h3>Day Report</h3>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <label>From Date</label>
                      <input
                        className="form-control"
                        type="date"
                        name="from_date"
                        onChange={(e) => {
                          setFromDate(e.target?.value);
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <label>To Date</label>
                      <input
                        className="form-control"
                        type="date"
                        name="to_date"
                        onChange={(e) => {
                          setToDate(e.target?.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="table-responsive">
                    {userState !== null && (
                      <DataTable2
                        lists={userState}
                        headers={headers}
                        searchItems={searchItems}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DayReport;
