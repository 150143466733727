import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../Toaster";
import axiosPrivate from "../../utils/http/axiosPrivate";
import classes from "../userForm/addUserStyle.module.css";
import moment from "moment";

// Validation rules
const validationRules = {
    name: Yup.string()
        .max(120, "Name must not exceed 120 characters")
        .required("Name is required"),
    symbol: Yup.string()
        .required("Currency Symbol is required"),
    value: Yup.string()
        .required("Currency Value is required"),
    from_date: Yup.date().required("From Date is required"),
    to_date: Yup.date().required("To Date is required"),
};

const AddCurrency = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            axiosPrivate
                .get(`currency/${id}`)
                .then((response) => {
                    showSuccessToast("Currency Fetched Successfully");
                    const data = response?.data?.data;
                    formik.setValues({
                        ...data,
                        from_date: moment(data.from_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
                        to_date: moment(data.to_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
                    });
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        error.response.data.errors.forEach(err => {
                            formik.setFieldError(err.param, err.msg);
                        });
                    }
                });
        }
    }, [id]);

    const formik = useFormik({
        initialValues: {
            name: "",
            symbol: "",
            value: "",
            from_date: "",
            to_date: "",
        },
        validationSchema: Yup.object(validationRules),
        onSubmit: (values, { setSubmitting, setFieldError }) => {
            const formattedValues = {
                ...values,
                from_date: moment(values.from_date).format("DD-MM-YYYY"),
                to_date: moment(values.to_date).format("DD-MM-YYYY"),
            };
            const request = id ? axiosPrivate.put(`currency/${id}`, formattedValues) : axiosPrivate.post('currency', formattedValues);

            request
                .then(() => {
                    showSuccessToast(`Currency ${id ? "Updated" : "Created"} Successfully`);
                    navigate("/admin/currency");
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        error.response.data.errors.forEach(err => {
                            setFieldError(err.param, err.msg);
                        });
                    } else {
                        showErrorToast(error.response?.data?.errors?.msg || error.message);
                    }
                });
        },
    });

    return (
        <div className="section-body mt-3">
            <div className="container-fluid">
                <div className="tab-content mt-3">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="tab-pane fade show active" id="currency-add" role="tabpanel">
                            <div className="card">
                                <div className="card-header">
                                    <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                                        Currency Details :
                                    </strong>
                                </div>
                                <div className="card-body">
                                    <div className="row clearfix">
                                        {/* Name */}
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label>
                                                Name:<span className="required">*</span>
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name *"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                />
                                                {formik.touched.name && formik.errors.name && (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* Symbol */}
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label>
                                                Symbol:<span className="required">*</span>
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    id="symbol"
                                                    name="symbol"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Symbol *"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.symbol}
                                                />
                                                {formik.touched.symbol && formik.errors.symbol && (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.symbol}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* Value */}
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label>
                                                Value:<span className="required">*</span>
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    id="value"
                                                    name="value"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Value *"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.value}
                                                />
                                                {formik.touched.value && formik.errors.value && (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.value}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* From Date */}
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label>
                                                From Date:<span className="required">*</span>
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    id="from_date"
                                                    name="from_date"
                                                    type="date"
                                                    className="form-control"
                                                    placeholder="From Date *"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.from_date}
                                                />
                                                {formik.touched.from_date && formik.errors.from_date && (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.from_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* To Date */}
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label>
                                                To Date:<span className="required">*</span>
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    id="to_date"
                                                    name="to_date"
                                                    type="date"
                                                    className="form-control"
                                                    placeholder="To Date *"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.to_date}
                                                />
                                                {formik.touched.to_date && formik.errors.to_date && (
                                                    <div className={classes.invalidDataError}>
                                                        {formik.errors.to_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* Submit Button */}
                                        <div className="col-md-12 col-sm-12 submit-btn">
                                            <div className="form-group">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    data-dismiss="modal"
                                                    onClick={() => navigate("/admin/currency")}
                                                >
                                                    Close
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary ml-2"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddCurrency;
