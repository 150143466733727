import classes from "../userForm/addUserStyle.module.css";
import * as Yup from "yup";
import Select from "react-select";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState, useCallback } from "react";
import BarcodeReader from "react-barcode-reader";
import { FaPlus, FaMinus } from "react-icons/fa";
import { debounce } from "lodash";
import { AutoComplete } from "primereact/autocomplete";
import axiosPrivate from "../../utils/http/axiosPrivate";
import LoaderContext from "../../context/Loader/LoaderContext";
import { showErrorToast, showSuccessToast } from "../../Toaster";
import { Dialog } from "primereact/dialog";

// mobile validation check regex
const phoneRegExp =
  /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;

// validation
const validationRules = {
  name: Yup.string()
    .max(120, "Party name must not exceed 120 characters")
    .required("Party name is required"),
  mobile: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
    college: Yup.object()
    .required("College name is required")
    .nullable(),  // To handle null/undefined va
  course: Yup.object()
    .required("Course is required")
    .nullable(),
  year: Yup.object()
    .required("Year is required")
    .nullable(),
  payment_mode: Yup.string()
  .required("Payment mode is required"),
};

const AddCashMemo = () => {
  const { id } = useParams();
  // const [docNo, setDocNo] = useState(1);
  // const [books, setBooks] = useState([]);
  // const [collegeName, setCollegeName] = useState("");
  // const [filteredCollegeData, setfilteredCollegeData] = useState([]);
  // const [isbn, setIsbn] = useState("");
  // const [itemisbnDetails, setItemisbnDetails] = useState([]);
  // const [selectedcashIsbn, setSelectedcashIsbn] = useState("");
  // const [debouncetimeout,setDebounceTimeout] = useState(null);
  const navigate = useNavigate();
  const [selectedIsbn, setSelectedIsbn] = useState("");
  const [showScanner, setShowScanner] = useState(false);
  const [indexItem, setIndexItem] = useState(0);
  const [discounType, setDiscountType] = useState("percentage");
  const [searchTerm, setSearchTerm] = useState("");
  const [itemDetails, setItemDetails] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [collegeResults, setCollegeResults] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [isDiscountInPercentage, setIsDiscountInPercentage] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedBookData, setSelectedBookData] = useState([]);
  const [totalItemAmountState, setTotalItemAmountState] = useState(0);
  const [isbnInput, setIsbnInput] = useState("");
  function debounce(fn, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  }

  const fetchBookData = (isbn) => {
    if (!isbn) return; // Guard clause to prevent unnecessary calls

    axiosPrivate
      .get(`/book-sold-reports/${isbn}`)
      .then((res) => {
        setSelectedBookData(res.data.data);
        console.log(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Create a debounced version of fetchBookData
  const debouncedFetchBookData = useCallback(
    debounce((isbn) => {
      fetchBookData(isbn);
    }, 1000),
    [] // Empty dependency array means this will be created once
  );

  // Handle ISBN input change
  const handleIsbnInputChange = (e) => {
    const isbn = e.target.value;
    setIsbnInput(isbn);
    debouncedFetchBookData(isbn); // Call the debounced function
  };

  const handleTitleClick = (index) => {
    // Retrieve the selected book data from formik values
    const selectedBookData = formik.values.items[index];
    // console.log(selectedBookData);
    // Set the selected book in state
    setSelectedBook(selectedBookData);
  };
  const { setLoaderCheck, loaderCheck } = useContext(LoaderContext);

  const formik = useFormik({
    initialValues: {
      name: "",
      doc: "",
      user_id: "",
      title: "",
      course: {
        id: "",
        label: "",
        value: "",
      },

      date: new Date().toISOString().slice(0, 10),
      mobile: "",
      college: {
        id: "",
        label: "",
        value: "",
        name: "",
      },
      year: {
        id: "",
        label: "",
        value: "",
      },
      freight: "",
      postage: "",
      advance: "",
      discount_type: false,
      round_off_discount: "",
      discount_percentage: 0,
      discount_percentage_value: 0,
      net_amount: 0,
      payment_mode: "",
      remarks: "",
      items: new Array(1).fill({
        book_id: "",
        isbn: "",
        title: "",
        publisher: "",
        quantity: "",
        author: "",
        currency: {
          id: "",
          label: "",
          value: "",
          name: "",
        },
        rate: 0,
        discount: 0,
        discount_value: 0,
        amount: 0,
      }),
    },
    validationSchema: Yup.object(validationRules),

    onSubmit: (values) => {
      handleSubmit(values, setLoaderCheck, navigate);
    },
    
  });

  const handleSubmit = async (values, setLoaderCheck, navigate) => {
    if (
      values.items.length === 1 &&
      values.items[0].isbn === "" &&
      values.items[0].quantity === ""
    ) {
      return;
    }

    // Filter out the empty items
    const filteredItems = values.items.filter(
      (item) => item.isbn !== "" || item.quantity !== ""
    );

    // Update the values.items with the filtered items
    values.items = filteredItems;

    values.items = values.items.map((item, index) => ({
      ...item,
    }));

    try {
      setLoaderCheck(true);
      const response = await axiosPrivate.post("cash-memo", values);
      setLoaderCheck(false);

      if (response.data.code == 201) {
        // Show success toast and navigate
        showSuccessToast("Cash Memo Created Successfully");
        // Open the URL in a new tab
        // window.open(response?.data?.file_url, "_blank");
        navigate("/admin/cash-memo");
      }
    } catch (error) {
      setLoaderCheck(false);
      if (Array.isArray(error?.response?.data?.errors)) {
        showErrorToast(error?.response?.data?.errors[0]?.msg);
      } else {
        showErrorToast(
          error.response.data.errors?.msg ?? error.response.errors?.message
        );
      }
    }
  };



  const [amountReceived, setAmountReceived] = useState(
    formik.values?.amount_received || 0
  );
  const [returnAmount, setReturnAmount] = useState(0);

  const handleAmountReceivedChange = (event) => {
    const received = parseFloat(event.target.value);
    setAmountReceived(received);
    // Calculate return amount
    const returned = received - formik.values.net_amount;
    setReturnAmount(returned);
  };
  //Call API to get last created cash memo no.
  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get("cash-memo/get")
      .then((response) => {
        setLoaderCheck(false);
        let doc = response?.data?.data?.doc;
        if (doc) {
          // Split the `doc` string
          const parts = doc.split("/");
          if (parts.length === 3) {
            const lastNumber = parseInt(parts[2], 10); // Parse the last number
            if (!isNaN(lastNumber)) {
              // Increment the last number by one
              const newLastNumber = lastNumber + 1;
              // Update the `doc` with the incremented value
              parts[2] = newLastNumber.toString();
              const newDoc = parts.join("/");
              formik.setFieldValue("doc", newDoc);
            }
          } else {
            formik.setFieldValue("doc", Number(doc) + 1);
          }
        }
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response?.status === 404) {
          formik.setFieldValue("doc", "APDPL/24-25/1");
        }
      });
  }, []);

  // Call the fetchResults function when the search term changes
  useEffect(() => {
    const delay = setTimeout(() => {
      if (searchTerm) {
        fetchResults();
      }
    }, 1000); // Adjust the delay time as needed (in milliseconds)

    return () => clearTimeout(delay);
  }, [searchTerm]);

  useEffect(() => {
    axiosPrivate
      .get("currency-at-cash-memo")
      .then((res) => {
        setCurrencyOptions(() => {
          const options = [];

          res?.data?.data?.forEach((currency) => {
            options.push({
              id: currency.id,
              name: currency.name,
              label: currency.symbol,
              value: currency.value,
            });
          });

          return options;
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("colleges")
      .then((res) => {
        setCollegeResults(() => {
          const options = [];

          res?.data?.data?.forEach((college) => {
            options.push({
              id: college.id,
              name: college.college_name,
              label: college.full_name,
              value: college.id,
            });
          });

          return options;
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    let totalItemAmount = 0;
    formik.values.items.forEach((item) => (totalItemAmount += item.amount));

    setTotalItemAmountState(totalItemAmount);
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      const {
        rate,
        quantity,
        discount,
        discount_value: discountValue,
        currency: { value: currencyValue } = {}
      } = formik.values.items[indexItem] || {};
  
      let totalItemAmount = formik.values.items.reduce((acc, item, index) => {
        return index !== indexItem ? acc + (item.amount || 0) : acc;
      }, 0);
  
      const calculateAmount = (rate, quantity, currencyValue, discount = 0) => {
        return rate * quantity * currencyValue - discount;
      };
  
      if (rate && quantity && currencyValue) {
        let amount = 0;
  
        if (discounType === "percentage") {
          const discountAmt = (rate * quantity * currencyValue * discount) / 100;
          amount = calculateAmount(rate, quantity, currencyValue, discountAmt);
          formik.setFieldValue(`items[${indexItem}].discount_value`, discountAmt);
        } else if (discountValue) {
          const discountPercentage =
            (discountValue / (rate * quantity * currencyValue)) * 100;
          amount = calculateAmount(rate, quantity, currencyValue, discountValue);
          formik.setFieldValue(`items[${indexItem}].discount`, discountPercentage);
        } else {
          amount = calculateAmount(rate, quantity, currencyValue);
        }
  
        formik.setFieldValue(`items[${indexItem}].amount`, amount);
        totalItemAmount += amount;
      } else {
        formik.setFieldValue(`items[${indexItem}].amount`, 0);
        formik.setFieldValue(`items[${indexItem}].discount`, 0);
        formik.setFieldValue(`items[${indexItem}].discount_value`, 0);
      }
  
      // Calculate total amount
      const { postage, freight, advance, round_off_discount: totalDiscount } =
        formik.values;
  
      const totalAmount =
        Number(totalItemAmount) + Number(postage) + Number(freight) - Number(advance);
  
      // Handle discount for the total amount
      if (isDiscountInPercentage) {
        const discountPercentageValue = (totalAmount * totalDiscount) / 100;
        const netAmount = totalAmount - discountPercentageValue;
        formik.setFieldValue("discount_percentage", totalDiscount);
        formik.setFieldValue("discount_percentage_value", discountPercentageValue);
        formik.setFieldValue("net_amount", netAmount);
      } else {
        const netAmount = totalAmount - totalDiscount;
        const discountPercentage = ((totalDiscount / totalAmount) * 100).toFixed(2);
        formik.setFieldValue("discount_percentage_value", totalDiscount);
        formik.setFieldValue("discount_percentage", discountPercentage);
        formik.setFieldValue("net_amount", netAmount);
      }
    }, 500);
    console.log("------>",totalItemAmountState);
    return () => clearTimeout(timer);
  }, [
    formik.values.items[indexItem]?.rate,
    formik.values.items[indexItem]?.currency?.value,
    formik.values.items[indexItem]?.quantity,
    formik.values.items[indexItem]?.discount,
    formik.values.items[indexItem]?.discount_value,
    formik.values.freight,
    formik.values.postage,
    formik.values.advance,
    formik.values.round_off_discount,
    isDiscountInPercentage,
  ]);
  

  // function to check if discount value is valid
  const isValidDiscountValue = (value) => {
    const rate = formik.values.items[indexItem]?.rate;
    const quantity = formik.values.items[indexItem]?.quantity;
    const currency_value = formik.values.items[indexItem]?.currency.value;

    if (rate * quantity * currency_value < value) {
      showErrorToast("Discount value should be less than total amount");
      return false;
    }
    return true;
  };

  const isValidDiscountPercentage = () => {
    const discountPercentage = formik.values.round_off_discount;
    if (discountPercentage > 100) {
      showErrorToast("Discount percentage should be less than 100");
      return false;
    }
    return true;
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target?.value);
  };

  //Function to add row into table grid
  const addItem = () => {
    formik.values.items.push({
      book_id: "",
      isbn: "",
      title: "",
      publisher_name: "",
      units: "",
      quantity: "",
      author: "",
      currency: {
        id: "",
        label: "",
        value: "",
      },
      rate: "",
      discount: 0,
      discount_value: 0,
      amount: "",
    });

    formik.setValues({ ...formik.values });

    setItemDetails([
      ...itemDetails,
      {
        isbn: "",
        title: "",
        publisher_name: "",
        units: "",
        quantity: "",
        currency: {
          id: "",
          label: "",
          value: "",
        },
        rate: "",
        discount: 0,
        discount_value: 0,
        amount: "",
      },
    ]);
  };

  //Function to remove row from table grid
  const removeItem = (index) => {
    if (index >= 0 && formik.values.items.length > 1) {
      // Remove item from formik values
      const updatedItems = [...formik.values.items];
      updatedItems.splice(index, 1);
      formik.setValues({ ...formik.values, items: updatedItems });

      // Remove item from item details
      setItemDetails((prevState) => {
        const updatedDetails = [...prevState];
        updatedDetails.splice(index, 1);
        return updatedDetails;
      });
    }
  };

  // API call to get data based on search keyword
  let debounceTimeout;

  // Function to fetch results based on the search term
  const fetchResults = async (searchTerm) => {
    try {
      const response = await axiosPrivate.get(`book-search/${searchTerm}`);
      const data = response?.data?.data;
      setSearchResults(data);
    } catch (err) {
      if (err.response?.status === 404) {
        showErrorToast(
          err?.response?.data?.message ||
            err?.response?.data?.errors[0]?.msg ||
            err?.response?.data?.errors?.msg
        );
      }
    }
  };

  // Function to format search suggestions with debouncing
  const searchSuggestions = useCallback(
    (event) => {
      const query = event.query;

      // Clear the previous timeout if it exists
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      // Set a new timeout to delay the execution of the function
      debounceTimeout = setTimeout(() => {
        fetchResults(query); // Fetch the results based on the query

        if (
          Array.isArray(searchResults) &&
          query?.length > 0 &&
          searchResults.length > 0
        ) {
          const formattedSuggestions = searchResults
            .filter((result) =>
              result.isbn?.toLowerCase().includes(query?.toLowerCase())
            )
            .map((result) => ({
              title: result.title,
              resultType: result.result_type,
              isbn: result.isbn,
              author: result.author,
              college: result.college,
              bookCategoryName: result.book_category_name,
              subCatName: result.sub_cat_name,
              publisherName: result.publisher_name,
              units: result.units,
              currencySymbol: result.currency,
              rate: result.mrp_rate,
              discount: result.discount,
              discountValue: result.discount_value,
            }));
          // Return or process the formatted suggestions as needed
          return formattedSuggestions;
        }
        return [];
      }, 300); // 300ms debounce time
    },
    [searchResults] // Dependencies
  );

  // Function to handle debounced search
  const debouncedIsbnSearch = debounce((index, isbn) => {
    if (!isbn) return; {
      let itemIndex = formik.values.items.findIndex(
        (item) => item?.isbn === isbn
      );
      if (itemIndex !== -1) {
        formik.setFieldValue(
          `items[${itemIndex}].quantity`,
          formik.values.items[itemIndex].quantity + 1
        );
      } else {
        axiosPrivate
          .get(`books/get/${isbn}`)
          .then((book) => {
            const updatedDetails = [...itemDetails];
            updatedDetails[index] = {
              book_id: book?.data?.data?.id ,
              title: book?.data?.data?.title ,
              publisher_name: book?.data?.data?.publisher_name ,
              units: book?.data?.data?.units ,
              rate: book?.data?.data?.mrp_rate ,
              discount: book?.data?.data?.discount,
              discount_value: book?.data?.data?.discount_value,
              author: book?.data?.data?.author ,
              currency: {
                id: book?.data?.data?.currency_id ,
                label: book?.data?.data?.currency_symbol ,
                value: book?.data?.data?.currency_value,
                name: book?.data?.data?.currency_name ,
              },
            };
            setItemDetails(updatedDetails);
            let amountSum =
              updatedDetails[index]?.rate -
              updatedDetails[index]?.rate *
                (updatedDetails[index]?.discount / 100);
            formik.setFieldValue(
              `items[${index}].book_id`,
              updatedDetails[index].book_id
            );
            formik.setFieldValue(
              `items[${index}].title`,
              updatedDetails[index].title
            );
            formik.setFieldValue(
              `items[${index}].publisher_name`,
              updatedDetails[index].publisher_name
            );
            formik.setFieldValue(
              `items[${index}].units`,
              updatedDetails[index].units
            );
            formik.setFieldValue(
              `items[${index}].discount`,
              updatedDetails[index]?.discount
            );
            formik.setFieldValue(
              `items[${index}].discount_value`,
              updatedDetails[index]?.discount_value
            );
            formik.setFieldValue(
              `items[${index}].rate`,
              updatedDetails[index]?.rate
            );
            formik.setFieldValue(
              `items[${index}].currency`,
              updatedDetails[index]?.currency
            );
            formik.setFieldValue(
              `items[${index}].author`,
              updatedDetails[index]?.author
            );
            formik.setFieldValue(`items[${index}].amount`, amountSum);
            formik.setFieldValue(`items[${index}].quantity`, 1);
            setSelectedIsbn("");
          })
          .catch((error) => {
            showErrorToast(error?.response?.data?.errors?.msg);
            formik.setFieldValue(`items[${index}].quantity`, null);
            formik.setFieldValue(`items[${index + 1}].quantity`, null);
          });
      }
    }
  }, 300); // 300ms delay
  const handleIsbnChange = (index, isbn) => {
    debouncedIsbnSearch(index, isbn);
  };

  const fetchDataThroughMobile = (mobile) => {
    axiosPrivate
      .get(`users-get-by-number/${mobile}`)
      .then((res) => {
        formik.setFieldValue("user_id", res.data.data.id);

        formik.setFieldValue("college", {
          label: res.data.data.college_name,
          value: res.data.data.college_name,
        });

        formik.setFieldValue("year", {
          label: res.data.data.year,
          value: res.data.data.year,
        });

        formik.setFieldValue("course", {
          label: res.data.data.course,
          value: res.data.data.course,
        });

        formik.setFieldValue("name", res.data.data.name);
      })
      .catch((error) => {
        showErrorToast(error?.response?.data?.errors?.msg);
      });
  };

  //Set isbn of selected item from search results
  const handleIsbnChangeForSelectedTitle = (e) => {
    if (e.value) {
      const selectedItem = e.value;
      // Check if the ISBN is already in the items array
      const existingIndex = formik.values.items.findIndex(
        (item) => item.isbn === selectedItem.isbn
      );

      if (existingIndex !== -1) {
        setIndexItem(existingIndex);
        // If the ISBN already exists, increment the quantity by 1
        const updatedQuantity =
          Number(formik.values.items[existingIndex].quantity) + 1;
        formik.setFieldValue(
          `items[${existingIndex}].quantity`,
          updatedQuantity
        );
      } else {
        // Find the next available empty index in the items array
        formik.values.items.push({
          book_id: "",
          isbn: "",
          title: "",
          publisher: "",
          quantity: "",
          currency: {
            id: "",
            label: "",
            value: "",
            name: "",
          },
          author: "",
          rate: 0,
          discount: 0,
          discount_value: 0,
          amount:   0,
        });

        const emptyIndex = formik.values.items.findIndex((item) => !item.isbn);

        if (emptyIndex !== -1) {
          formik.setFieldValue(`items[${emptyIndex}].isbn`, selectedItem.isbn);
          handleIsbnChange(emptyIndex, selectedItem.isbn);
        }
      }
    }
  };

  const handleIsbnChangee = (index, isbn) => {
    if (isbn) {
      let itemIndex = formik.values.items.findIndex(
        (item) => item?.isbn === isbn
      );
      if (itemIndex !== -1) {
        formik.setFieldValue(
          `items[${itemIndex}].quantity`,
          formik.values.items[itemIndex].quantity + 1
        );
      } else {
        if (isbn.length >5 ) {
        axiosPrivate
          .get(`books/get/${isbn}`)
          .then((book) => {
            const updatedDetails = [...itemDetails];
            updatedDetails[index] = {
              title: book?.data?.data?.title || "",
              publisher_name: book?.data?.data?.publisher_name || "",
              rate: book?.data?.data?.mrp_rate || "",
              discount: book?.data?.data?.discount || 0,
              discount_value: book?.data?.data?.discount_value || 0,
              author: book?.data?.data?.author || "",
              currency: {
                id: book?.data?.data?.currency_id || "",
                label: book?.data?.data?.currency_symbol || "",
                value: book?.data?.data?.currency_value || "",
                name: book?.data?.data?.currency_name || "",
              },
            };

            setItemDetails(updatedDetails);

            let amountSum =
              updatedDetails[index]?.rate -
              updatedDetails[index]?.rate *
                (updatedDetails[index]?.discount / 100);

            formik.setFieldValue(
              `items[${index}].title`,
              updatedDetails[index].title
            );
            formik.setFieldValue(
              `items[${index}].publisher_name`,
              updatedDetails[index].publisher_name
            );
            formik.setFieldValue(
              `items[${index}].discount`,
              updatedDetails[index]?.discount || 0
            );
            formik.setFieldValue(
              `items[${index}].discount_value`,
              updatedDetails[index]?.discount_value || 0
            );

            formik.setFieldValue(
              `items[${index}].rate`,
              updatedDetails[index]?.rate
            );
            formik.setFieldValue(
              `items[${index}].currency`,
              updatedDetails[index]?.currency
            );
            formik.setFieldValue(
              `items[${index}].author`,
              updatedDetails[index]?.author
            );
            formik.setFieldValue(`items[${index}].amount`, amountSum);
            formik.setFieldValue(`items[${index}].quantity`, 1);

            setSelectedIsbn("");
          })
          .catch((error) => {
            if (error.response?.code === 404) {
              showErrorToast(error?.response?.message || 'No data found');
              
              // Clear the fields based on the index
              formik.setFieldValue(`items[${index}].quantity`, '');
              formik.setFieldValue(`items[${index + 1}].quantity`, '');
            } else {
              // Handle other errors (optional)
              showErrorToast('An unexpected error occurred');
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleIsbnChangee(indexItem, isbnInput);
    }, 300);
    
  }, [formik.values.items[indexItem]?.isbn]);



  
  // const handleIsbnInChange = async (isbn) => {
  //   try {
  //     const response = await axiosPrivate.get(`book-search/${isbn}`);
  //     const book = response?.data?.data[0];
  //     let amountSum = book?.mrp_rate * 1;
  //       formik.setFieldValue(`items[${indexItem}].book_id`, book?.id);
  //       formik.setFieldValue(`items[${indexItem}].title`, book?.title);
  //       formik.setFieldValue(`items[${indexItem}].publisher_name`, book?.publisher_name);
  //       formik.setFieldValue(`items[${indexItem}].units`, book?.units);
  //       formik.setFieldValue(`items[${indexItem}].discount`, book?.discount);
  //       formik.setFieldValue(`items[${indexItem}].discount_value`, book?.discount_value);
  //       formik.setFieldValue(`items[${indexItem}].rate`, book?.mrp_rate);
  //       formik.setFieldValue(`items[${indexItem}].currency?.value`, book?.currency);
  //       formik.setFieldValue(`items[${indexItem}].author`, book?.author);
  //       formik.setFieldValue(`items[${indexItem}].amount`, amountSum);
  //       formik.setFieldValue(`items[${indexItem}].quantity`, 1);

  //       // setSelectedIsbn(""); // Clear the selected ISBN input
      
  //   } catch (err) {
  //     console.error("Error fetching book data: ", err);
  //   }
  // };

  // useEffect(() => {
  //   // Set a timeout to simulate debounce (optional, if you still want a slight delay)
  //   const timer = setTimeout(() => {
  //     handleIsbnInChange(isbnInput);
  //   }, 1000); // Adjust the delay as needed

  //   // Cleanup the timeout on component unmount or when the isbn changes
  //   return () => clearTimeout(timer);
  // }, [isbnInput]); // Depen
  // const handleIsbnInputChangee = (e) => {
  //   const value = e.target.value;
  //   setIsbnInput(value);
  //   handleIsbnInChange(indexItem, value); // Assuming first index, adjust as needed
  // };


  return (
    <>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <form onSubmit={(e) => formik.handleSubmit(e)}>
              <div
                className="tab-pane fade show active"
                id="cash-memo-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header d-flex">
                    <strong
                      className="cash-memo-head"
                      style={{ fontWeight: "700", fontSize: "18px" }}
                    >
                      Cash Memo Details :
                    </strong>
                  </div>

                  <div className="card-body pt-0">
                    <div className="row">
                      <div className="col">
                        <div className="form-group align-items-center">
                          <label style={{ fontSize: "15px" }}>
                            Mobile:<span className="required">*</span>
                          </label>
                          <input
                            id="mobile"
                            name="mobile"
                            type="number"
                            className="form-control"
                            placeholder="Mobile *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.mobile}
                            onInput={(e) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10);
                              }
                              if (e.target.value.length === 10) {
                                fetchDataThroughMobile(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div
                          style={{
                            position: "relative",
                            bottom: 15,
                            left: 7,
                          }}
                        >
                          {formik.touched.mobile && formik.errors.mobile ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.mobile}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Name */}
                      <div className="col">
                        <div className="form-group align-items-center">
                          <label style={{ fontSize: "15px" }}>
                            Party name:<span className="required">*</span>
                          </label>
                          <input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Party name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.name}
                          />
                        </div>
                        <div
                          style={{
                            position: "relative",
                            bottom: 15,
                            left: 7,
                          }}
                        >
                          {formik.touched.name && formik.errors.name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col">
                        <div className="form-group align-items-center">
                          <label style={{ fontSize: "15px" }}>
                            Date :<span className="required">*</span>
                          </label>
                          <input
                            id="date"
                            name="date"
                            type="date"
                            className="form-control"
                            placeholder="Enter date *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.date}
                          />
                        </div>
                      </div>
                      {/* Payment mode */}
                      <div className="col">
                        <div className="form-group align-items-center mb-0">
                          <label style={{ fontSize: "15px" }}>
                            Payment:<span className="required">*</span>
                          </label>
                          <Select
                            className="w-100"
                            options={[
                              { label: "Cash", value: "Cash" },
                              {
                                label: "UPI",
                                value: "Cash Sale (Paytm/Gpay/Google)",
                              },
                              { label: "Card", value: "Card Sale A/c" },
                              { label: "Cheque", value: "Card Sale A/c" },
                              { label: "NEFT/RTGS", value: "Card Sale A/c" },
                            ]}
                            onChange={(e) => {
                              formik.setFieldValue("payment_mode", e.value);
                            }}
                            placeholder="Payment mode"
                          />
                        </div>
                        <div
                          style={{
                            display: "relative",
                            bottom: 15,
                            left: 7,
                          }}
                        >
                          {formik.touched.payment_mode &&
                          formik.errors.payment_mode ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.payment_mode}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="form-group align-items-center">
                          <label style={{ fontSize: "15px" }}>
                            Doc No:<span className="required">*</span>
                          </label>
                          <input
                            id="doc"
                            name="doc"
                            type="text"
                            className="form-control"
                            placeholder="Doc No *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.doc}
                            readOnly
                          />
                          {formik.touched.doc && formik.errors.doc ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.doc}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* College */}
                      <div className="col">
                        <div className="form-group align-items-center">
                          <label style={{ fontSize: "15px" }}>College:</label>
                          <Select
                            onChange={(e) => {
                              formik.setFieldValue('college', e);
                            }}
                            className="w-100"
                            menuPlacement="auto"
                            defaultValue={formik.values?.college}
                            value={formik.values?.college}
                            options={collegeResults}
                            styles={{
                              control: (base) => ({
                                ...base,
                                overflow: "auto",
                              }),
                            }}
                          />
                        </div>
                        <div
                          style={{
                            position: "relative",
                            bottom: 15,
                            left: 7,
                          }}
                        >
                          {formik.touched.college && formik.errors.college ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.college}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* course */}
                      <div className="col">
                        <div className="form-group align-items-center">
                          <label style={{ fontSize: "15px" }}>Course:</label>
                          <Select
                            className="w-100"
                            onChange={(e) => {
                              formik.setFieldValue(`course`, e);
                            }}
                            value={formik.values?.course}
                            options={[
                              { value: "B.Tech", label: "B.Tech", id: 11 },
                              { value: "M.Tech", label: "M.Tech", id: 22 },
                              { value: "MBA", label: "MBA", id: 33 },
                              { value: "BBA", label: "BBA", id: 44 },
                              { value: "BCA", label: "BCA", id: 55 },
                            ]}
                            placeholder="course"
                          />
                        </div>
                        <div
                          style={{ position: "relative", bottom: 15, left: 7 }}
                        >
                          {formik.touched.course && formik.errors.course ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.course}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group align-items-center">
                          <label style={{ fontSize: "15px" }}>Year:</label>
                          <Select
                            className="w-100"
                            onChange={(e) => {
                              formik.setFieldValue(`year`, e);
                            }}
                            value={formik.values?.year}
                            options={[
                              { value: "1", label: "1st", id: 1 },
                              { value: "2", label: "2nd", id: 2 },
                              { value: "3", label: "3rd", id: 3 },
                              { value: "4", label: "4th", id: 4 },
                              { value: "5", label: "5th", id: 5 },
                            ]}
                            defaultValue={formik.values?.year}
                            placeholder="Year"
                          />
                        </div>
                        <div
                          style={{
                            position: "relative",
                            bottom: 15,
                            left: 7,
                          }}
                        >
                          {formik.touched.year && formik.errors.year ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.year}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/* search */}
                    <div className="row">
                      <div className="d-flex mt-3 col-md-12 form-group">
                        <div className=" d-flex align-items-center">
                          <label
                            className="col-auto label-full-width m-0 label-class"
                            style={{ fontSize: "17px" }}
                          >
                            Search:
                          </label>
                          <AutoComplete
                            value={searchTerm}
                            suggestions={searchResults}
                            field="title"
                            completeMethod={searchSuggestions} // This is where you bind the function
                            onChange={(e) => handleInputChange(e)}
                            onFocusCapture={(e) => setSearchResults([])} // Clear the search results when the input is focused
                            itemTemplate={(item) => (
                              <div className="form-control">
                                {item.isbn} / {item.author} / {item.title} /{" "}
                                {item.publisher_name} / {item.currency} /{" "}
                                {item.mrp_rate}
                              </div>
                            )}
                            placeholder="Search..."
                            className="col d-flex"
                            onSelect={(e) => {
                              handleIsbnChangeForSelectedTitle(e);
                              setSearchTerm("");
                            }}
                            inputClassName="form-control"
                            style={{ width: "50%" }} // Ensure the AutoComplete component takes full width
                            panelStyle={{ width: "60%" }} // Ensure the dropdown panel matches the input width
                          />
                        </div>
                      </div>
                    </div>
                    <label className="col-auto font-weight-bold cash-memo-head">
                      ITEMS:
                    </label>
                    <div
                      className="table-responsive border"
                      style={{ height: "fit-content", overflowY: "scroll" }}
                    >
                      <table className="table table-bordered mb-0">
                        <thead>
                          <tr className="border-bottom">
                            <th className="font-weight-bold">Sr.</th>
                            <th className="pr-3 col-md-1 font-weight-bold">
                              ISBN{" "}
                            </th>
                            <th className="col-md-5 font-weight-bold">Title</th>
                            {/* <th className="font-weight-bold">Publisher</th> */}
                            <th className="font-weight-bold">Curr.</th>
                            <th className="font-weight-bold">QTY.</th>
                            <th className="font-weight-bold">Rate</th>
                            <th className="font-weight-bold">Disc(%)</th>
                            <th className="font-weight-bold">Disc.</th>
                            <th className="font-weight-bold">Amount</th>
                            <th className="font-weight-bold">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formik.values.items.map((item, index) => (
                            <tr
                              key={index}
                              onClick={() => {
                                handleTitleClick(index);
                              }}
                            >
                              {/* Index No */}
                              <td
                                className="p-0"
                                style={{ textAlign: "center" }}
                              >
                                {index + 1}
                              </td>
                              {/* ISBN */}
                              <td className="p-0">
                                <input
                                  type="text"
                                  name={`items[${index}].isbn`}
                                  id={`items[${index}].isbn`}
                                  className="form-control"
                                  placeholder="Enter ISBN or Scan"
                                  value={
                                    formik?.values?.items[index]?.isbn || ""
                                  }
                                  onChange={(e) => {
                                    if (e.target.value.includes("!")) return;
                                    formik.handleChange(e);
                                    setSelectedIsbn(e.target.value);
                                    handleIsbnChangee(index, e.target.value);
                                  }}
                                  readOnly
                                />

                                {/* Barcode scanner, shown only when the input is focused */}
                                {/* {showScanner && (
                                  <BarcodeReader
                                    onScan={handleBarcodeScan} // Set scanned value
                                    onError={(err) =>
                                      console.error("Scanner Error: ", err)
                                    }
                                  />
                                )} */}
                              </td>
                              {/* Title */}
                              <td className="p-0">
                                <input
                                  id={`items[${index}].title`}
                                  name={`items[${index}].title`}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Title"
                                  onChange={(e) => {
                                    if (e.target.value.includes("!")) return;
                                    formik.handleChange();
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik.values.items[index]?.title || ""
                                  }
                                  readOnly
                                />
                              </td>
                              {/* Publisher */}
                              {/* <td className="p-0">
                                <input
                                  id={`items[${index}].publisher_name`}
                                  name={`items[${index}].publisher_name`}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Publisher"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik.values.items[index]
                                      ?.publisher_name || ""
                                  }
                                />
                              </td> */}
                              {/* Currency */}
                              <td className="p-0">
                                <Select
                                  menuPlacement="auto"
                                  defaultValue={
                                    formik.values.items[index]?.currency
                                  }
                                  value={formik.values.items[index]?.currency}
                                  onChange={(e) => {
                                    setIndexItem(index);
                                    formik.setFieldValue(
                                      `items[${index}].currency`,
                                      {
                                        name: e.name,
                                        label: e.label,
                                        value: e.value,
                                        id: e.id,
                                      }
                                    );
                                  }}
                                  options={currencyOptions}
                                  styles={{
                                    control: (base) => ({
                                      ...base,
                                      overflow: "auto",
                                    }),
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 9999,
                                    }),
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                />
                              </td>
                              {/* Quantity */}
                              <td className="p-0">
                                <input
                                  id={`items[${index}].quantity`}
                                  name={`items[${index}].quantity`}
                                  type="number"
                                  className="number form-control"
                                  placeholder="Enter Quantity"
                                  onChange={(e) => {
                                    setIndexItem(index);
                                    formik.setFieldValue(
                                      `items[${index}].quantity`,
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik.values.items[index]?.quantity || ""
                                  }
                                />
                              </td>
                              {/* Rate */}
                              <td className="p-0">
                                <input
                                  id={`items[${index}].rate`}
                                  name={`items[${index}].rate`}
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Rate"
                                  onChange={(e) => {
                                    setIndexItem(index);
                                    formik.setFieldValue(
                                      `items[${index}].rate`,
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.items[index]?.rate || ""}
                                />
                              </td>
                              {/* Discount */}
                              <td className="p-0">
                                <input
                                  id={`items[${index}].discount`}
                                  name={`items[${index}].discount`}
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Discount"
                                  onChange={(e) => {
                                    setIndexItem(index);
                                    setDiscountType("percentage");
                                    if (e.target.value > 100) return;
                                    formik.setFieldValue(
                                      `items[${index}].discount`,
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik?.values?.items[index]?.discount
                                      ? Number(
                                          formik.values.items[index].discount
                                        )
                                      : ""
                                  }
                                />
                              </td>
                              <td className="p-0">
                                <input
                                  id={`items[${index}].discount_value`}
                                  name={`items[${index}].discount_value`}
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Discount Value"
                                  onChange={(e) => {
                                    setIndexItem(index);
                                    setDiscountType("value");
                                    if (!isValidDiscountValue(e.target.value))
                                      return;
                                    formik.setFieldValue(
                                      `items[${index}].discount_value`,
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik.values.items[index]
                                      ?.discount_value || ""
                                  }
                                />
                              </td>
                              {/* Amount */}
                              <td className="p-0">
                                <input
                                  id={`items[${index}].amount`}
                                  name={`items[${index}].amount`}
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Amount"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik.values.items[index]?.amount < 0
                                      ? 0
                                      : Number(
                                          formik.values.items[index]?.amount ||
                                            0
                                        ).toFixed(2)
                                  }
                                  readOnly
                                />
                              </td>
                              {/* Action */}
                              <td className="p-0 d-flex justify-content-center">
                                {index >= 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => removeItem(index)}
                                  >
                                    <FaMinus />
                                  </button>
                                )}
                                {index + 1 === formik.values.items.length && (
                                  <button
                                    type="button"
                                    className="btn btn-primary ml-2"
                                    onClick={addItem}
                                  >
                                    <FaPlus />
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}

                          <tr>
                            <th colSpan={7} className="m-0 p-0">
                              <th className="col-auto">
                                Author: {selectedBook?.author}
                              </th>
                              <th className="col-auto">
                                Pub: {selectedBook?.publisher_name}
                              </th>
                              <th className="col-auto">
                                Stock: {selectedBook?.units}
                              </th>
                            </th>
                            <td className="p-2" colSpan={1}>
                              Total Amount
                            </td>
                            <td className="pl-3 p-2 label bg-light" colSpan={1}>
                              {typeof totalItemAmountState === "number"
                                ? totalItemAmountState.toFixed(2)
                                : "0.00"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {/* <div className="table-responsive mt-3 bordered ">
                      <table className="table-bordered font-weight-light">
                        <thead>
                          <tr>
                            <th className="col-auto font-weight-light">Author: {selectedBook?.author}</th>
                            <th className="col-auto font-weight-light">Pub: {selectedBook?.publisher_name}</th>
                            <th className="col-auto font-weight-light">Stock: </th>
                          </tr>
                        </thead>
                      </table>
                    </div> */}

                    <div className="row col-md-auto mb-2 justify-content-end">
                      <div className="row col-md-auto bg-light p-1 justify-content-center"></div>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                      {/* Left area  */}
                      <div className="col-md-5 col-sm-5 form-group">
                        <div className="col-12 font-weight-bold cash-memo-head">
                          Remarks :
                        </div>
                        <div className="mt-2">
                          <textarea
                            id="remarks"
                            name="remarks"
                            type="text"
                            placeholder="Enter Remarks"
                            style={{ height: "110px" }}
                            className="form-control"
                            value={formik.values?.remarks}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>

                      {/* Right area */}
                      <div className="row justify-content-end mt-2">
                        <div className="p-2  d-flex flex-row justify-content-end">
                          <div className="d-flex flex-column justify-content-center mr-2 col-md-5">
                            <div className=" form-group d-flex align-items-center">
                              <label
                                className="col"
                                style={{ fontSize: "15px" }}
                              >
                                Freight:
                              </label>
                              <input
                                id="freight"
                                name="freight"
                                type="number"
                                className="form-control col"
                                placeholder="Enter Freight *"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values?.freight}
                              />
                            </div>
                            <div className="form-group d-flex align-items-center">
                              <label
                                className="col"
                                style={{ fontSize: "15px" }}
                              >
                                Postage:
                              </label>
                              <input
                                id="postage"
                                name="postage"
                                type="number"
                                className="form-control col"
                                placeholder="Enter postage *"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values?.postage}
                              />
                            </div>

                            {/* Advance */}
                            <div className="form-group d-flex align-items-center">
                              <label
                                className="col"
                                style={{ fontSize: "15px" }}
                              >
                                Advance:
                              </label>
                              <input
                                id="advance"
                                name="advance"
                                type="number"
                                className="form-control col"
                                placeholder="Enter advance *"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values?.advance}
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-column justify-content-center col-md-5">
                            <div className="form-group d-flex align-items-center">
                              <div className="row">
                                <div className="col-auto d-flex align-items-center">
                                  <label
                                    className="col-auto"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Discount :
                                  </label>
                                  <input
                                    id="discount_type"
                                    name="discount_type"
                                    type="checkbox"
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.discount_type}
                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      if (!isValidDiscountPercentage()) return;
                                      setIsDiscountInPercentage(
                                        !isDiscountInPercentage
                                      );
                                    }}
                                    checked={isDiscountInPercentage}
                                  />{" "}
                                  %
                                </div>
                                <div className="d-flex col">
                                  <input
                                    id="round_off_discount"
                                    name="round_off_discount"
                                    type="number"
                                    className="form-control mr-2"
                                    placeholder="Enter discount *"
                                    onChange={(e) => {
                                      if (
                                        isDiscountInPercentage &&
                                        e.target.value > 100
                                      )
                                        return;
                                      formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.round_off_discount}
                                  />
                                  <input
                                    type="text"
                                    id="discount_percentage"
                                    name="discount_percentage"
                                    className="form-control"
                                    placeholder="Enter discount percentage *"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                      formik.values?.discount_percentage_value
                                    }
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Round Off */}
                            <div className="form-group d-flex align-items-center">
                              <label
                                className="col-auto"
                                style={{ fontSize: "15px", width: "130px" }}
                              >
                                Round Off :
                              </label>
                              <input
                                id="rount_off"
                                name="rount_off"
                                type="number"
                                className="form-control col"
                                placeholder="Enter rount off *"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={0.0}
                                disabled={true}
                              />
                            </div>
                            {/* Net Amount */}
                            <div className="form-group d-flex align-items-center">
                              <label
                                className="col-auto"
                                style={{ fontSize: "15px", width: "130px" }}
                              >
                                Net Amount :
                              </label>
                              <input
                                id="net_amount"
                                name="net_amount"
                                type="number"
                                className="form-control col"
                                placeholder="Enter net amount *"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values?.net_amount.toFixed(2)}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mr-2 row d-flex justify-content-end">
                      <div className="form-group d-flex align-items-center mt-3 mr-2">
                        <label className="col" style={{ fontSize: "15px" }}>
                          Amount Received :{" "}
                          <input
                            id="amount_received"
                            name="amount_received"
                            type="number"
                            className="col form-control mt-1"
                            placeholder="Enter Amount Received *"
                            onChange={handleAmountReceivedChange}
                            value={amountReceived}
                          />
                        </label>
                      </div>

                      <div className="row justify-content-center align-items-center mt-3 p-3 mr-2">
                        {" "}
                        -{" "}
                      </div>

                      <div className="form-group d-flex align-items-center mt-3 mr-2">
                        <label className="col" style={{ fontSize: "15px" }}>
                          Net Amount
                          <input
                            id="net_amount"
                            name="net_amount"
                            type="number"
                            className="col form-control mt-1"
                            placeholder="Net Amount *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.net_amount.toFixed(2)}
                            readOnly
                          />
                        </label>
                      </div>

                      <div className="row justify-content-center align-items-center mt-3 p-3 mr-2">
                        {" "}
                        ={" "}
                      </div>

                      <div className="form-group d-flex align-items-center mt-3">
                        <label className="col" style={{ fontSize: "15px" }}>
                          Return
                          <input
                            id="return_amount"
                            name="return_amount"
                            type="number"
                            className="col form-control mt-1"
                            placeholder="Return Amount"
                            value={returnAmount.toFixed(2)}
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12 submit-btn">
                      <div className="form-group close-submit">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={() => navigate("/admin/cash-memo")}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary ml-2"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Dialog
        header="Sold Book Details"
        // headerStyle={{ paddingLeft: "6%"}}
        visible={visible}
        style={{ margin: "auto", width: "80%", height: "80%" }}
        onHide={() => setVisible(false)}
      >
        <div className="table-responsive">
          <table className="table table-striped table-bordered border-top">
            <thead>
              <tr>
                <th>Doc No</th>
                <th>ISBN</th>
                <th>Title</th>
                <th>MRP Rate</th>
                <th>Quantity</th>
                <th>Currency</th>
                <th>Discount Value</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {selectedBookData?.map((item, index) => (
                <tr key={index}>
                  <td>{item.doc}</td>
                  <td>{item.isbn}</td>
                  <td>{item.title}</td>
                  <td>{item.rate}</td>
                  <td>{item.quantity}</td>
                  <td>{item.currency}</td>
                  <td>{item.discount_value}</td>
                  <td>{item.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Dialog>
    </>
  );
};

export default AddCashMemo;
