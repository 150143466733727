import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
// import { MultiSelect } from "react-multi-select-component";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../Toaster";
import axiosPrivate from "../../utils/http/axiosPrivate";
import classes from "../userForm/addUserStyle.module.css";

//mobile validation check rajex
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// validation

const validationRules = {
  name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Name is required"),
};

const AddUser = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [bookCategories, setBookCategories] = useState([]);

  useEffect(() => {
    axiosPrivate
      .get("publishers")
      .then((book_categories) => {
        setBookCategories(book_categories?.data?.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 422) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              formik.setFieldError(
                error.response.data.errors[i].param,
                error.response.data.errors[i].msg
              );
            }
          }
        }
      });
  }, []);

  useEffect(() => {
    if (id) {
      axiosPrivate
        .get(`publishers/${id}`)
        .then((response) => {
            console.log(response);
          formik.setValues(response?.data?.data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                formik.setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    }
  }, [id]);

  const formik = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema: Yup.object(validationRules),

    onSubmit: (values, { setSubmitting, setFieldError }) => {
      if (id) {
        axiosPrivate
          .put(`publishers/${id}`, values)
          .then((response) => {
            showSuccessToast("Book Publishers Updated Successfully");
            navigate("/admin/book-publishers");
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 422) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  setFieldError(
                    error.response.data.errors[i].param,
                    error.response.data.errors[i].msg
                  );
                }
              }
            }
          });
      } else {
        try {
          axiosPrivate
            .post('publishers', values)
            .then((response) => {
              showSuccessToast("Book Publisher created successfully");
              navigate('/admin/book-publishers');
            })
            .catch((error) => {
              if (typeof error.response.data.errors == 'array') {
                showErrorToast(error.response.data.errors[0]?.msg);
              } else {
                showErrorToast(error.response.data.errors?.msg ?? error.response.errors?.message);
              }
            });
        } catch (error) {
          console.error(error);
        }
      }
    }
  });


  return (
    <>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <form onSubmit={(e) => formik.handleSubmit(e)}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                      Book Publishers :
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      {/* name */}
                      <div className="col-lg-12 col-md-4 col-sm-12">
                        <label>
                          Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Parent Book Publishers */}
                      {/* <div className="col-md-4 col-sm-12">
                        <label>
                          Parent Book Publishers
                        </label>

                        <div className="form-group">
                          <select
                            id="parent_id"
                            name="parent_id"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.parent_id}
                          >
                            <option value="" label="Select a parent category" />
                            {bookCategories.map((book) => (
                              <option
                                key={book.id}
                                value={book.id}
                                label={book.name}
                              />
                            ))}
                          </select>
                        </div>
                      </div> */}

                      <div className="col-md-12 col-sm-12 submit-btn">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => navigate("/admin/book-publishers")}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
