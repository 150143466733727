import { Formik, useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import classes from "./Roles.module.css";
import AlertContext from "../../context/Alert/AlertContext";
import axiosPrivate from "../../utils/http/axiosPrivate";
const validationRules = {
  name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Role Name is required"),
};

const EditRoles = () => {
  let { id } = useParams();
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  //   const [roles, setRoles] = useState([]);
  const [getData, setGetData] = useState({});
  const [initialValues, setInitialValues] = useState({
    name: "",
  });

  //   useEffect(()=>{
  //     axiosPrivate.get('roles')
  //         .then(user_roles=>{
  // 			setRoles(user_roles?.data?.data);
  // 		  }
  // 		)
  // 		.catch(error => {
  // 			if (error.response) {
  // 				//response status is an error code
  // 				console.log(error.response.status);
  // 			}
  // 			else if (error.request) {
  // 				//response not received though the request was sent
  // 				console.log(error.request);
  // 			}
  // 			else {
  // 				//an error occurred when setting up the request
  // 				console.log(error.message);
  // 			}
  // 		});
  //   },[]);

  useEffect(() => {
    axiosPrivate
      .get(`roles/${id}`)
      .then((response) => {
        if (response.data.code == 200) {
          setGetData({ ...response.data.data, password: "" });
        }
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
          console.log(error.response.status);
        } else if (error.request) {
          //response not received though the request was sent
          console.log(error.request);
        } else {
          //an error occurred when setting up the request
          console.log(error.message);
        }
      });
  }, []);

  useEffect(() => {
    if (getData != "") {
      formik.setValues({ ...getData });
    }
  }, [getData]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      axiosPrivate
        .put(`roles/${id}`, values)
        .then((response) => {
          setAlertMessage({ message: "role updated successfully" });
          navigate("/admin/roles");
          console.log(response);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    },
  });

  return (
    <div className="section-body mt-3">
      <div className="container-fluid">
        <div className="tab-content mt-3">
          <form onSubmit={formik.handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="user-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <strong>Edit Roles</strong>
                </div>
                <div className="card-body">
                  <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group">
                        <input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Department Name *"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12">
                      <hr className="mt-4" />
                      <div className="table-responsive">
                        <table className="table table-striped"></table>
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary ml-2"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/roles")}
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditRoles;
