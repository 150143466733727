import { useContext, useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, showSuccessToast } from "../../Toaster";
import axiosPrivate from "../../utils/http/axiosPrivate";
import AuthContext from "../../context/auth/AuthContext";
import DataTable2 from "../../context/components/DataTable2";
import { FaGreaterThan } from "react-icons/fa";
import { GrView } from "react-icons/gr";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import moment from 'moment';
import { SquareLoader } from "react-spinners";  // Ensure you have installed react-loader-spinner

const BooksList = ({ authPermission }) => {
  const { authPermissions } = useContext(AuthContext);
  const [booksState, setBooksState] = useState([]);
  const [selectedBook, setSelectedBook] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const footerContent = (
    <div>
      <Button label="Close" icon="pi pi-times" onClick={() => setVisible(true)} className="p-button-text" />
    </div>
  );

  const navigate = useNavigate();

  const headers = [
    { name: "ISBN", field: "isbn", sortable: true, classKey: "" },
    { name: "Title", field: "title", sortable: true, classKey: "" },
    { name: "Publisher", field: "publisher_name", sortable: true, classKey: "" },
    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["title", "isbn", "publisher_name"];

  const getBooks = () => {
    setLoading(true);
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("books", options)
      .then((books) => {
        // showSuccessToast("Books List Fetched Successfully");
        const booksData = books?.data?.data?.map((value, key) => {
          let buttons = [];
          buttons.push(
            <button
              key="viewButton##1"
              type="button"
              data-id={value.id}
              onClick={() => bookViewHandler(value.id)}
              className="btn btn-icon js-sweetalert"
              title="View"
            >
              <GrView style={{ color: "red" }} size={17} />
            </button>
          );
          buttons.push(
            <Link
              key="editButton##1"
              type="button"
              to={`/admin/books/edit/${value.id}`}
              className="btn btn-icon"
              title="Edit"
            >
              <BiEdit style={{ color: "green" }} size={18} />
            </Link>
          );
          buttons.push(
            <button
              key="deleteButton##1"
              type="button"
              data-id={value.id}
              onClick={() => bookDeleteHandler(value.id)}
              className="btn btn-icon js-sweetalert"
              title="Delete"
            >
              <RiDeleteBinLine style={{ color: "red" }} size={17} />
            </button>
          );
          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
        });

        setBooksState(booksData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          showErrorToast(error?.response?.data?.message);
        }
      });
  };

  const fetchfromtally = () => {
    setLoading(true);
    axiosPrivate
      .get(`fetch-books`)
      .then((response) => {
        if (response.data.code === 200) {
          getBooks();
          showSuccessToast("Books Fetched Successfully!");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          showErrorToast(error?.response?.data?.message);
        }
      });
  }

  useEffect(() => {
    getBooks();
  }, []);

  const bookViewHandler = (id) => {
    setLoading(true);
    axiosPrivate
      .get(`books/${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedBook(response?.data?.data); // Store the selected book
          setVisible(true); // Open the dialog
          showSuccessToast("Book Fetched Successfully!");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          showErrorToast(error?.response?.data?.message);
        }
      });
  };

  const bookDeleteHandler = (id) => {
    setLoading(true);
    axiosPrivate
      .delete(`books/${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          navigate("/admin/books");
          getBooks();
          showSuccessToast("Book Deleted Successfully!");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          showErrorToast(error?.response?.data?.errors?.msg);
        }
      });
  };

  return (
    <>
      <div className="mx-sm-5 mx-0">
        <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center mb-3">
          <ul className="nav nav-tabs page-header-tab">
            <li className="nav-item">
              <div className="card-header">
                <div className="breadcrumb-area">
                  {/* header heading */}
                  <h1 className="card-title text-dark" style={{ fontWeight: "700", fontSize: "18px" }}>
                    Books
                  </h1>
                  {/* header routing */}
                  <ol className="headerroutes breadcrumb m-0 bg-none ">
                    <li className="item color-gray">|</li>
                    <li className="item">
                      <Link to="/admin" className="item mx-2" style={{ fontSize: "14px" }}>
                        Dashboard
                      </Link>
                    </li>
                    <li className="item">
                      <FaGreaterThan style={{ fontSize: "14px" }} />
                    </li>
                    <li className="item mx-2 mt-1" style={{ fontSize: "14px" }}>
                      Books list
                    </li>
                  </ol>
                </div>
              </div>
            </li>
          </ul>
          <div className="row">
          <div className="col"><button className="btn btn-primary" onClick={fetchfromtally}>Fetch from Tally</button></div>
          <div>
            <Link to="/admin/books/add" className="text-decoration-none">
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="fe fe-plus mr-2" />
                Add
              </button>
            </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div className="tab-pane fade show active" id="book-list" role="tabpanel">
              <div className="card">
                <div className="card-body">
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                      <SquareLoader type="ThreeDots" color="#004583" height={80} width={80} />
                    </div>
                  ) : (
                    <div className="table-responsive">
                      {booksState !== null && (
                        <DataTable2 lists={booksState} headers={headers} searchItems={searchItems} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Dialog component */}
      <Dialog header="Book Details" visible={visible} style={{ width: '50vw', margin: "auto" }} onHide={() => setVisible(false)}>
        {selectedBook && (
          <div className="row" style={{ maxWidth: "750px", margin: "auto" }}>
            {/* Display book details here */}
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p style={{ textAlign: "left" }}><strong>ISBN:</strong> {selectedBook.isbn}</p>
              <p><strong>Title:</strong> {selectedBook.title}</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p><strong>Alias:</strong> {selectedBook.alias}</p>
              <p><strong>Book Category:</strong> {selectedBook.book_category_name}</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p><strong>Book Sub Category:</strong> {selectedBook.sub_cat_name}</p>
              <p><strong>Prising Type:</strong> {selectedBook.prising_type}</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p><strong>Author:</strong> {selectedBook.author}</p>
              <p><strong>Edition:</strong> {selectedBook.edition}</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p><strong>Volume:</strong> {selectedBook.volume}</p>
              <p><strong>Publication Date:</strong> {selectedBook.pub_date !== null ? moment(selectedBook?.pub_date).format('DD-MM-YYYY') : "N A"}</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p><strong>Year:</strong> {selectedBook.year}</p>
              <p><strong>MRP Rate:</strong> {selectedBook.mrp_rate}</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p><strong>MRP Rate1:</strong> {selectedBook.mrp_rate1}</p>
              <p><strong>Discount:</strong> {selectedBook.discount}</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p><strong>Sales Discount:</strong> {selectedBook.sales_discount}</p>
              <p><strong>Bank Rate:</strong> {selectedBook.bank_rate}</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p><strong>Currency:</strong> {selectedBook.currency}</p>
              <p><strong>Binding:</strong> {selectedBook.binding}</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p><strong>Publisher:</strong> {selectedBook.publisher_name}</p>
              <p><strong>Subject:</strong> {selectedBook.subject}</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p><strong>Sub Subject:</strong> {selectedBook.sub_subject}</p>
              <p><strong>Subject2:</strong> {selectedBook.subject2}</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p><strong>Sub Subject2:</strong> {selectedBook.sub_subject2}</p>
              <p><strong>Units:</strong> {selectedBook.units}</p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p><strong>Rate of Duty:</strong> {selectedBook.rate_of_duty}</p>
            </div>
            {/* Add other book details as needed */}
          </div>
        )}
      </Dialog>
    </>
  );
};

export default BooksList;
