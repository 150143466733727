import {useState,useCallback, useEffect} from 'react';
import AlertContext from './AlertContext';

const AlertState = (props) => {
    const [alertMessage, setAlertMessage] = useState(null);

    return (
        <AlertContext.Provider value={{ alertMessage, setAlertMessage }}>
            {props.children}
        </AlertContext.Provider>
    );
}

export default AlertState;